module.exports = {
    firstname: 'givenName',
    lastname: 'familyName',
    organizationname: 'organization',
    orgname: 'organization',
    phone: 'tel',
    goto: 'goToUrl',
    rc: 'referralCode',
    originalurl: 'originalUrl',
};
