/* eslint prefer-promise-reject-errors: 0 */
import Cookies from 'universal-cookie';
import {
    isProdEnv,
    getQueryParamVal,
    getAbsoluteUrl,
    sanitizeString,
    handleSSOPostSignup,
    updateCookieValueByInput,
    getCookieDomain,
} from '.';

import {
    extendAccountSettingsData,
    getSOParts,
} from '../../../components/form/utils';

const rg4js = typeof window !== 'undefined' ? require('raygun4js') : null;

export const getDefaultParams = (
    baseUrl,
    paramsToCheckFor = [
        'AID',
        'bypassratelimiter',
        'cc',
        'clickid',
        'ic',
        'IRCID',
        'kid',
        'PID',
        'pmc',
        'pn',
        'rc',
        'rmc',
        'SID',
        'utm_adgroup',
        'utm_campaign',
        'utm_content',
        'utm_medium',
        'utm_source',
        'utm_term',
    ]
) => {
    let location = '';
    let ctctfosReferrerForNatsearchCheck = document?.referrer || null;
    if (typeof window !== 'undefined' && window?.optimizely?.get) {
        const optimizelyWebRedirectExperimentReferrer = window?.optimizely
            ?.get('state')
            ?.getRedirectInfo()?.referrer
            ? window.optimizely.get('state').getRedirectInfo().referrer
            : null;
        if (optimizelyWebRedirectExperimentReferrer) {
            // override document referrer in Tealium UDO when running Optimizely Web redirect experiments
            ctctfosReferrerForNatsearchCheck =
                optimizelyWebRedirectExperimentReferrer;
        }
    }
    if (window !== 'undefined') {
        location = window.location;
    }
    const queryParams = location?.search || '';
    let queryString = '';
    if (ctctfosReferrerForNatsearchCheck) {
        queryString = `${queryString}&ctctfos_referrer_for_natsearch_check=${ctctfosReferrerForNatsearchCheck}`;
    }
    queryString = paramsToCheckFor.reduce((acc, next) => {
        const qsParamVal = getQueryParamVal(queryParams, next);
        if (qsParamVal) {
            acc += `&${next}=${qsParamVal}`;
        }
        return acc;
    }, queryString);
    // if queryString string is currently at least '&{x}'
    if (queryString?.length >= 2 && queryString?.startsWith('&')) {
        return `${baseUrl}?${queryString.substring(1)}`;
    }
    return baseUrl;
};

export const getURLParams = (route) => {
    try {
        return new URL(route);
    } catch (err) {
        return {
            hash: '',
            host: '',
            hostname: '',
            href: '',
            origin: '',
            password: '',
            pathname: '',
            port: '',
            protocol: '',
            search: '',
            searchParams: new URLSearchParams(),
            username: '',
        };
    }
};

export const addRouteParams = (route) => {
    const routeUrl = getURLParams(route);
    const { protocol, hostname, pathname, search } = routeUrl;
    if (
        hostname &&
        hostname.startsWith('go.') &&
        hostname.endsWith('.constantcontact.com')
    ) {
        const routeWithParams = getDefaultParams(
            `${protocol}${hostname}${pathname}`
        );
        const newRouteUrl = getURLParams(routeWithParams);
        const newRouteUrlQueryParams = newRouteUrl?.search;
        if (newRouteUrlQueryParams && newRouteUrl instanceof URL) {
            const newParamsToAdd = new URLSearchParams(search);
            for (const [key, value] of newParamsToAdd) {
                newRouteUrl.searchParams.append(key, value);
            }
            return newRouteUrl.toString();
        }
    }
    return route;
};

/* marked for deprecation */
export const request = (options) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.open(options.method, options.url);

        if (options.headers) {
            Object.keys(options.headers).forEach((key) => {
                xhr.setRequestHeader(key, options.headers[key]);
            });
        }

        xhr.addEventListener('load', () => {
            if (xhr.status >= 200 && xhr.status < 400) {
                resolve({
                    status: xhr.status,
                    response: xhr.response,
                });
            } else {
                reject({
                    status: xhr.status,
                    response: xhr.response,
                });
            }
        });

        xhr.addEventListener('error', (e) => {
            reject({
                status: xhr.status,
                response: xhr.response,
            });
        });

        xhr.withCredentials = true;

        xhr.send(options.body);
    });
};

const getHostName = (webAddress) => {
    try {
        const url = new URL(webAddress);
        return url.hostname;
    } catch (error) {
        return '';
    }
};

export const callFetch = (
    route,
    options = {},
    onSuccessCb = () => {},
    onErrorCb = () => {},
    onFinallyCb = () => {},
    onApiFailure = () => {}
) => {
    const cookies = new Cookies();
    const csrfToken = cookies.get('XSRF-TOKEN');
    const routeWithParams = addRouteParams(route);
    const hostName = getHostName(route);

    let fetchOptions =
        route && route?.match(/constantcontact.com/)
            ? {
                  ...options,
                  credentials: 'include',
              }
            : {
                  ...options,
              };
    if (getHostName(window.location.href) !== hostName) {
        /* make CORS mode dynamic */
        fetchOptions = {
            ...fetchOptions,
            mode: 'cors',
        };
    }

    const requiresCSRFToken =
        (csrfToken && isProdEnv() && hostName === 'go.constantcontact.com') ||
        (csrfToken &&
            hostName.startsWith('go') &&
            hostName.endsWith('constantcontact.com'));

    if (requiresCSRFToken) {
        fetchOptions = {
            ...fetchOptions,
            headers: {
                ...fetchOptions.headers,
                'X-XSRF-TOKEN': csrfToken,
            },
        };
    }
    let httpStatus;

    return fetch(routeWithParams, fetchOptions)
        .then((response) => {
            httpStatus = response.status;
            return response.json().then((json) => ({ json, response }));
        })
        .then(({ json, response }) => {
            if (!response.ok) {
                json.status = response.status;
                return Promise.reject(json);
            }
            return json;
        })
        .then(
            (response) => {
                if (onSuccessCb && typeof onSuccessCb === 'function') {
                    onSuccessCb(response, options, httpStatus);
                }
                return { response };
            },
            (error) => {
                const err = {
                    error: {
                        ...error,
                        status: httpStatus || error?.status,
                        message: error.error_description || error.message,
                    },
                };
                if (onErrorCb && typeof onErrorCb === 'function') {
                    onErrorCb(err, options);
                }
                return err;
            }
        )
        .catch((err) => {
            /* for complete API failures, connection errors, malformed return data etc. */
            if (onApiFailure && typeof onApiFailure === 'function') {
                onApiFailure(err, options);
            }
        })
        .finally(() => {
            if (onFinallyCb && typeof onFinallyCb === 'function') {
                onFinallyCb();
            }
        });
};

const getUrlParam = (paramName) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(paramName);
};

export const processSSO = async (
    accountOwnerUserUuid,
    isAutoDeployForm = false
) => {
    try {
        const formAction = getAbsoluteUrl(
            'https://go.constantcontact.com/api/signup-api/provision-social-signup-google'
        );

        const hiddenFieldValsSignUpForm = JSON.parse(
            sessionStorage.getItem('hidden-field-vals-sign-up-form')
        );

        let body = {
            ...hiddenFieldValsSignUpForm,
            socialProvider: getUrlParam('socialProvider') || 'google',
            accountOwnerUserUuid,
        };

        // default value should be 'LastName' unless it's a buy flow
        const flowType = getSOParts(
            hiddenFieldValsSignUpForm?.siteOwnerProperties
        )?.CTCT_Website_Signup_Flow;
        const familyName = flowType === 'buy' ? 'LastNameBN' : 'LastName';

        /* we do not need goToUrl */
        if (body?.goToUrl) {
            delete body.goToUrl;
        }

        /* geolocation data is handled by SSO, we do not need this */
        if (body?.hasOwnProperty('state')) {
            delete body.state;
        }

        /* geolocation data is handled by SSO, we do not need this */
        if (body?.hasOwnProperty('province')) {
            delete body.province;
        }

        if (body?.hasOwnProperty('eHawkTalon')) {
            delete body.eHawkTalon;
        }

        if (!body?.familyName) {
            body = {
                ...body,
                familyName,
            };
        }

        if (body?.accountSettings) {
            body = {
                ...body,
                accountSettings: extendAccountSettingsData(
                    body.accountSettings,
                    getUrlParam('socialProvider') || 'google'
                ),
            };
        }

        const originalUrlValue = body?.originalUrl;

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(body),
            credentials: 'include',
        };

        const onSSOFailure = (error, opts) => {
            const originUrlParts = new URL(
                sessionStorage.getItem('ssoSignupOrigin')
            );
            const {
                origin: domain,
                pathname: originPathname,
                search: originSearch,
            } = originUrlParts;
            const { error: apiResponseError } = error;

            const errorCode = 500;

            if (rg4js) {
                rg4js('send', {
                    error: apiResponseError,
                    tags: ['sso_error'],
                    customData: {
                        opts,
                    },
                });

                rg4js('onAfterSend', (xhrResponse) => {
                    const targetUrl =
                        !originalUrlValue ||
                        originalUrlValue.includes('/sso-signup/processing')
                            ? `${domain}${originPathname}${
                                  originSearch
                                      ? `${originSearch}&error_code=${errorCode}`
                                      : `?error_code=${errorCode}`
                              }`
                            : `${originalUrlValue}?error_code=${errorCode}`;

                    window.location.href = targetUrl;
                });
            } else {
                const targetUrl =
                    !originalUrlValue ||
                    originalUrlValue.includes('/sso-signup/processing')
                        ? `${domain}${originPathname}${
                              originSearch
                                  ? `${originSearch}&error_code=${errorCode}`
                                  : `?error_code=${errorCode}`
                          }`
                        : `${originalUrlValue}?error_code=${errorCode}`;

                window.location.href = targetUrl;
            }
        };

        const onSSOSuccess = (res, opts, httpStatus) => {
            const { firstName, lastName, souid } = res;
            const cookies = new Cookies();
            const doNotSaveCookies = cookies.get('gdpr_opt_out_eu');

            if (!doNotSaveCookies && flowType === 'try') {
                const phonyLastNames = ['LastNameBN', 'LastName'];

                if (firstName) {
                    cookies.set(
                        'suf-values',
                        updateCookieValueByInput(cookies.get('suf-values'), {
                            name: 'givenName',
                            value: firstName,
                        }),
                        {
                            path: '/',
                            domain: getCookieDomain(),
                            sameSite: 'lax',
                        }
                    );
                }

                if (lastName && !phonyLastNames.includes(lastName)) {
                    cookies.set(
                        'suf-values',
                        updateCookieValueByInput(cookies.get('suf-values'), {
                            name: 'familyName',
                            value: lastName,
                        }),
                        {
                            path: '/',
                            domain: getCookieDomain(),
                            sameSite: 'lax',
                        }
                    );
                }
            }

            if (souid) {
                const destinationUrl = sessionStorage.getItem(
                    'inProvisionFlowDestinationUrl'
                );
                const socialProvider =
                    getUrlParam('socialProvider') || 'google';

                if (!isAutoDeployForm) {
                    handleSSOPostSignup(
                        souid,
                        destinationUrl,
                        flowType,
                        socialProvider
                    );
                }
            } else {
                rg4js('send', {
                    error: 'No SOUID returned from signup-api/provision-social-signup-google',
                    tags: ['sso_error'],
                });
            }
        };

        try {
            const result = await callFetch(
                formAction,
                options,
                onSSOSuccess,
                onSSOFailure
            );

            // if callFetch returns a result with an error status
            if (result?.response?.provisionStatus === 'FAILURE') {
                // Call onSSOFailure with the error and options
                onSSOFailure(result, options);
            }
        } catch (error) {
            // Call onSSOFailure with the error and options
            onSSOFailure(error, options);
        }
    } catch (error) {
        // Handle other errors that might occur during the process here
        rg4js('send', {
            error,
            tags: ['sso_error'],
        });

        // Retrieve originalUrlValue from sessionStorage
        const hiddenFieldValsSignUpForm = JSON.parse(
            sessionStorage.getItem('hidden-field-vals-sign-up-form')
        );

        const originalUrlValue = hiddenFieldValsSignUpForm?.originalUrl;

        // Redirect with error data in the URL
        window.location.href = `${originalUrlValue}?error=500&error_description=${error.message}`;
    }
};
