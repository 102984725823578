// this is now only used by our getLocalePathPrefix(...) helper function
module.exports = [
    '/au',
    '/ca',
    '/co',
    '/de',
    '/es',
    '/fr',
    '/in',
    '/mx',
    '/nz',
    '/uk',
    '/global',
];
