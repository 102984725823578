module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Constant Contact","short_name":"ctct","start_url":"/","background_color":"#5BACD7","theme_color":"#5BACD7","display":"minimal-ui","icon":"src/assets/images/ctct_2022_icon_32x32_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9d6cc04e04a2e8962b4fd50aab7d5834"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
