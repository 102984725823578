/* eslint no-useless-constructor: 0 */
import { memoize } from 'lodash';

const sameValueZero = (x, y) => x === y || (Number.isNaN(x) && Number.isNaN(y));

const makeShallowArrayEquals = comparators => a => b => {
    return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => {
            const comparator =
                typeof comparators === 'function'
                    ? comparators
                    : comparators[index] || sameValueZero;
            return comparator(val, b[index]);
        })
    );
};

const list = (...args) => args;

class Cache extends Map {
    constructor(args) {
        super(args);
    }

    shallowArrayEquals = makeShallowArrayEquals(sameValueZero);

    delete(key) {
        const keys = Array.from(this.keys());
        const foundKey = keys.find(this.shallowArrayEquals(key));
        return super.delete(foundKey);
    }

    get(key) {
        const keys = Array.from(this.keys());
        const foundKey = keys.find(this.shallowArrayEquals(key));
        return super.get(foundKey);
    }

    has(key) {
        const keys = Array.from(this.keys());
        return keys.findIndex(this.shallowArrayEquals(key)) !== -1;
    }
}

const memoizeFn = (fn, { resolver = list, comparators = sameValueZero }) => {
    const { Cache: OriginalCache } = memoize;
    memoize.Cache = Cache;
    const memoized = memoize(fn, resolver || list);
    memoized.cache.shallowArrayEquals = makeShallowArrayEquals(comparators);
    memoize.Cache = OriginalCache;
    return memoized;
};

export default memoizeFn;
