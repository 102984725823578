/* eslint-disable no-restricted-globals */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'whatwg-fetch';
import 'core-js';
import 'regenerator-runtime/runtime';
import Cookies from 'universal-cookie';
import {
    getTealiumEnv,
    getQueryParamVal,
    isProdEnv,
    loadScript,
    unloadScriptFilenameOnly,
    moveToAnchorId,
    cookiebotId,
    cookiebotJsUrl,
    transcendJsUrl,
    transcendJsTestUrl,
    gdprCountries,
} from './src/assets/scripts/helpers';

const cookies = new Cookies();
const countryCode = cookies.get('ctct-geolocated-country-code');
const appendCookiebotJs = () => {
    if (typeof window !== 'undefined' && !window?.CookieConsent) {
        loadScript(cookiebotJsUrl, false, {
            id: 'Cookiebot',
            'data-cbid': cookiebotId,
            'data-blockingmode': 'auto',
            'data-georegions':
                "{'region':'US-06','cbid':'0b732793-8382-4d70-9050-c95355d4f3a5'}",
        });
    }
};

const appendTranscendJs = () => {
    let transcendScript;
    if (isProdEnv()) {
        transcendScript = transcendJsUrl;
    } else {
        transcendScript = transcendJsTestUrl;
    }

    if (typeof window !== 'undefined' && !window?.airgap) {
        loadScript(transcendScript, false);
    }
};

if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

export const onClientEntry = () => {
    // D1, L1 preview, L1 PR, L1, S1, and prod only
    if (
        typeof window !== 'undefined' &&
        window.location.hostname.includes('.constantcontact.com')
    ) {
        // Cookiebot - Reload Cookiebot Banner & Privacy Trigger JS (cd.js) to reinitialize Cookiebot on every route except /login/account for all countries and for gdpr all pages under /legal).
        if (
            window.location.pathname !== '/login/account' &&
            !(
                gdprCountries.includes(countryCode) &&
                window.location.pathname.startsWith('/legal/')
            )
        ) {
            appendTranscendJs();
            appendCookiebotJs();
        }

        const jqueryJs = appendJqueryJs();

        jqueryJs.addEventListener('load', () => {
            appendTealiumJS();
        });

        preloadTealiumEndpoint();
    }
};

/*
    The Gatsby Link component by default will bring you to
    the scrolled position of the page you're navigating to
    the last time you visited that page. This fix makes it
    so every time you visit a page it shows the top of the
    page. Also "top of the page to ya - hey sha shay" 🍀
*/
export const onInitialClientRender = () => {
    if (typeof window !== 'undefined' && window.location.href.includes('#')) {
        moveToAnchorId(window.location.href);
    } else {
        window.scrollTo(0, 0);
    }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
    // Tealium for D1, L1, S1, and Prod
    if (location.hostname.endsWith('.constantcontact.com')) {
        getCTCTPhoneNumberAndTealiumUDOAndCallUtagView(location, prevLocation);
    }

    // Optimizely Web
    if (typeof window !== 'undefined') {
        // clear window vars set by Optimizely Web
        window.optTophatBanner = undefined; // set in Optimizely Web
        window.renderedOptTophatBanner = undefined; // set in layout.jsx
    }

    // Drift
    if (typeof window !== 'undefined' && window.drift) {
        window?.drift?.page(); // fire generic page event
    }

    // Cookiebot - Reload Cookiebot Banner & Privacy Trigger JS (cd.js) to reinitialize Cookiebot on every route except /login/account for all countries and for gdpr all pages under /legal).
    if (
        window.location.pathname !== '/login/account' &&
        !(
            gdprCountries.includes(countryCode) &&
            window.location.pathname.startsWith('/legal/')
        )
    ) {
        appendTranscendJs();
        appendCookiebotJs();
    }

    appendUtilJs();
};

const getCtctLandingPagesGoSubdomain = () => {
    let incomingHostname = '';
    if (typeof window !== 'undefined') {
        incomingHostname = window.location.hostname;
    }

    if (incomingHostname.indexOf('.d1.') !== -1) {
        return 'https://go.d1.constantcontact.com';
    }
    if (incomingHostname.indexOf('.l1.') !== -1) {
        return 'https://go.l1.constantcontact.com';
    }
    if (incomingHostname.indexOf('.s1.') !== -1) {
        return 'https://go.s1.constantcontact.com';
    }
    return 'https://go.constantcontact.com';
};

// TODO: edit preload, test plan = phone # & tealium & PPC vs. nat search
const preloadTealiumEndpoint = () => {
    const endpoint = '';
    if (typeof window !== 'undefined') {
        getCtctLandingPagesBackEndEndpointUrl(
            '/get/phone-number-and-tealium-udo',
            window.location,
            ''
        );
    }
    const link = document.createElement('link');

    link.setAttribute('href', endpoint);
    link.setAttribute('rel', 'preload');
    link.setAttribute('as', 'fetch');
    link.setAttribute('crossorigin', 'use-credentials');

    document.head.appendChild(link);
};

const appendTealiumJS = () => {
    let incomingHostname = '';
    if (typeof window !== 'undefined') {
        incomingHostname = window.location.hostname;
    }

    const tealiumSyncJs = document.createElement('script');
    const tealiumJs = document.createElement('script');
    const tealiumEnv = getTealiumEnv(incomingHostname);

    tealiumSyncJs.src = `https://tags.tiqcdn.com/utag/cc/main/${tealiumEnv}/utag.sync.js`;
    document.head.appendChild(tealiumSyncJs);

    tealiumJs.innerHTML = `window.utag_cfg_ovrd = {noview : true};\n\n(function(a,b,c,d) {\n\ta="https://tags.tiqcdn.com/utag/cc/main/${tealiumEnv}/utag.js";\n\tb=document;\n\tc="script";\n\td=b.createElement(c);\n\td.src=a;\n\td.type="text/java"+c;\n\td.async=true;\n\ta=b.getElementsByTagName(c)[0];\n\ta.parentNode.insertBefore(d,a);\n})();`;
    document.body.prepend(tealiumJs);
};

const appendJqueryJs = () => {
    const jqueryJs = document.createElement('script');
    jqueryJs.src = '/scripts/jquery-3.6.0.min.js';
    document.body.appendChild(jqueryJs);

    return jqueryJs;
};

const fireTealiumUtagViewEvent = (
    tealiumUDO,
    fireTealiumUtagViewEventInterval
) => {
    const { utag } = window;

    try {
        utag.view(tealiumUDO);

        clearInterval(fireTealiumUtagViewEventInterval);
        return true;
    } catch (error) {
        window.console.info(`Tealium utag.js not loaded yet: ${error}`);
    }
};

const appendUtilJs = () => {
    const utilScript = document.createElement('script');
    const timestamp = new Date().getTime();
    utilScript.src = `/scripts/ctctUtil.js?_=${timestamp}`;
    utilScript.type = 'text/javascript';
    utilScript.async = true;
    document.body.appendChild(utilScript);

    return utilScript;
};

const getCtctLandingPagesBackEndEndpointUrl = (
    endpointPathname,
    location,
    prevLocation
) => {
    const endpointWithoutQueryString =
        getCtctLandingPagesGoSubdomain() + endpointPathname;

    let ctctfosReferrerForNatsearchCheck = '';
    if (prevLocation) {
        ctctfosReferrerForNatsearchCheck =
            prevLocation.origin + prevLocation.pathname;
    } else {
        ctctfosReferrerForNatsearchCheck = document.referrer;
    }

    if (
        typeof window !== 'undefined' &&
        typeof window.optimizely !== 'undefined' &&
        typeof window.optimizely.get !== 'undefined'
    ) {
        const optimizelyWebRedirectExperimentReferrer =
            window.optimizely.get('state').getRedirectInfo() &&
            window.optimizely.get('state').getRedirectInfo().referrer;
        if (optimizelyWebRedirectExperimentReferrer) {
            // override document referrer in Tealium UDO when running Optimizely Web redirect experiments
            ctctfosReferrerForNatsearchCheck =
                optimizelyWebRedirectExperimentReferrer;
        }
    }

    const queryParams = location.search;

    // check for NEW incoming CTCT landingPages marketing-specific query params to send to landingPages
    const AID = getQueryParamVal(queryParams, 'AID'); // b/c originally configured as ALL CAPS for affiliates
    const bypassratelimiter = getQueryParamVal(
        queryParams,
        'bypassratelimiter'
    ); // for bypassing our landingPages rate limiter for provisioning
    const cc = getQueryParamVal(queryParams, 'cc');
    const clickid = getQueryParamVal(queryParams, 'clickid');
    const ic = getQueryParamVal(queryParams, 'ic');
    const IRCID = getQueryParamVal(queryParams, 'IRCID'); // b/c originally configured as ALL CAPS for affiliates
    const kid = getQueryParamVal(queryParams, 'kid');
    const PID = getQueryParamVal(queryParams, 'PID'); // b/c originally configured as ALL CAPS for affiliates
    const pmc = getQueryParamVal(queryParams, 'pmc');
    const pn = getQueryParamVal(queryParams, 'pn');
    const rc = getQueryParamVal(queryParams, 'rc');
    const rmc = getQueryParamVal(queryParams, 'rmc');
    const SID = getQueryParamVal(queryParams, 'SID'); // b/c originally configured as ALL CAPS for affiliates
    const utmAdGroup = getQueryParamVal(queryParams, 'utm_adgroup');
    const utmCampaign = getQueryParamVal(queryParams, 'utm_campaign');
    const utmContent = getQueryParamVal(queryParams, 'utm_content');
    const utmMedium = getQueryParamVal(queryParams, 'utm_medium');
    const utmSource = getQueryParamVal(queryParams, 'utm_source');
    const utmTerm = getQueryParamVal(queryParams, 'utm_term');
    const debugMode = decodeURIComponent(
        getQueryParamVal(queryParams, 'DEBUG')
    );
    const countryCodeForLocalTesting = getQueryParamVal(
        queryParams,
        'country-code'
    );
    const stateCodeForLocalTesting = getQueryParamVal(
        queryParams,
        'state-code'
    );
    const provinceCodeForLocalTesting = getQueryParamVal(
        queryParams,
        'province-code'
    );

    let ctctLandingPagesBackEndEndpointUrl = endpointWithoutQueryString;
    let queryString = '';

    if (ctctfosReferrerForNatsearchCheck) {
        queryString = `${queryString}&ctctfos_referrer_for_natsearch_check=${ctctfosReferrerForNatsearchCheck}`;
    }

    if (AID) {
        queryString = `${queryString}&AID=${AID}`; // b/c originally configured as ALL CAPS for affiliates
    }

    if (bypassratelimiter) {
        queryString = `${queryString}&bypassratelimiter=${bypassratelimiter}`;
    }

    if (cc) {
        queryString = `${queryString}&cc=${cc}`;
    }

    if (clickid) {
        queryString = `${queryString}&clickid=${clickid}`;
    }

    if (ic) {
        queryString = `${queryString}&ic=${ic}`;
    }

    if (IRCID) {
        queryString = `${queryString}&IRCID=${IRCID}`; // b/c originally configured as ALL CAPS for affiliates
    }

    if (kid) {
        queryString = `${queryString}&kid=${kid}`;
    }

    if (PID) {
        queryString = `${queryString}&PID=${PID}`; // b/c originally configured as ALL CAPS for affiliates
    }

    if (pn) {
        queryString = `${queryString}&pn=${pn}`;
    }

    if (pmc) {
        queryString = `${queryString}&pmc=${pmc}`;
    }

    if (rc) {
        queryString = `${queryString}&rc=${rc}`;
    }

    if (rmc) {
        queryString = `${queryString}&rmc=${rmc}`;
    }

    if (SID) {
        queryString = `${queryString}&SID=${SID}`; // b/c originally configured as ALL CAPS for affiliates
    }

    if (utmAdGroup) {
        queryString = `${queryString}&utm_adgroup=${utmAdGroup}`;
    }

    if (utmCampaign) {
        queryString = `${queryString}&utm_campaign=${utmCampaign}`;
    }

    if (utmContent) {
        queryString = `${queryString}&utm_content=${utmContent}`;
    }

    if (utmMedium) {
        queryString = `${queryString}&utm_medium=${utmMedium}`;
    }

    if (utmSource) {
        queryString = `${queryString}&utm_source=${utmSource}`;
    }

    if (utmTerm) {
        queryString = `${queryString}&utm_term=${utmTerm}`;
    }

    if (debugMode) {
        queryString = `${queryString}&DEBUG=${debugMode}`;
    }

    if (countryCodeForLocalTesting) {
        queryString = `${queryString}&country-code=${countryCodeForLocalTesting}`;
    }

    if (stateCodeForLocalTesting) {
        queryString = `${queryString}&state-code=${stateCodeForLocalTesting}`;
    }

    if (provinceCodeForLocalTesting) {
        queryString = `${queryString}&province-code=${provinceCodeForLocalTesting}`;
    }

    // if queryString string is currently at least '&{x}'
    if (queryString.length >= 2 && queryString.startsWith('&')) {
        ctctLandingPagesBackEndEndpointUrl = `${ctctLandingPagesBackEndEndpointUrl}?${queryString.substring(
            1
        )}`;
    }

    return ctctLandingPagesBackEndEndpointUrl;
};

const getCTCTPhoneNumberAndTealiumUDOAndCallUtagView = (
    location,
    prevLocation
) => {
    const endpoint = getCtctLandingPagesBackEndEndpointUrl(
        '/get/phone-number-and-tealium-udo',
        location,
        prevLocation
    );

    fetch(endpoint, { credentials: 'include' })
        .then((dataWrappedByPromise) => dataWrappedByPromise.json())
        .then((data) => {
            let ctctPhoneNumberAndTealiumUDOObj = {};

            try {
                ctctPhoneNumberAndTealiumUDOObj = JSON.parse(data);
            } catch (error) {
                console.error(
                    'getCTCTPhoneNumberAndTealiumUDOAndCallUtagView(...) - ERROR while parsing data as JSON.'
                );
            }

            // Fire custom events
            const setPartnerCompanyNameFromBackEndCustomEvent = new CustomEvent(
                'setPartnerCompanyNameFromBackEndCustomEvent',
                {
                    detail: {
                        partnerCompanyName:
                            ctctPhoneNumberAndTealiumUDOObj.tealiumUDO
                                .partnerCompanyName,
                    },
                }
            );
            window.dispatchEvent(setPartnerCompanyNameFromBackEndCustomEvent);

            const setTealiumUDOCustomEvent = new CustomEvent(
                'setTealiumUDOCustomEvent',
                {
                    detail: {
                        tealiumUDO: ctctPhoneNumberAndTealiumUDOObj.tealiumUDO,
                    },
                }
            );
            window.dispatchEvent(setTealiumUDOCustomEvent);

            // CTCT - Entitlement phone #
            const phoneNumber =
                ctctPhoneNumberAndTealiumUDOObj.entitlementPhoneNumber;
            if (phoneNumber) {
                const phoneNumberAnchorTags = document.querySelectorAll(
                    '.navbar__phone-number'
                );

                for (let i = 0; i < phoneNumberAnchorTags.length; i++) {
                    // if (window.innerWidth <= 999) {
                    //     phoneNumberAnchorTags[i].setAttribute(
                    //         'href',
                    //         `tel:${phoneNumber}`
                    //     );
                    //     phoneNumberAnchorTags[i].textContent = phoneNumber;
                    // }
                }
            }

            // CTCT - landingPages - Tealium UDO
            const { tealiumUDO } = ctctPhoneNumberAndTealiumUDOObj;
            tealiumUDO.hostName = location.hostname;
            tealiumUDO.pageName = location.pathname;

            let referrer = '';
            if (prevLocation) {
                referrer = prevLocation.origin + prevLocation.pathname;
            } else {
                referrer = document.referrer;
            }

            if (
                typeof window !== 'undefined' &&
                typeof window.optimizely !== 'undefined' &&
                typeof window.optimizely.get !== 'undefined'
            ) {
                const optimizelyWebRedirectExperimentReferrer =
                    window.optimizely.get('state').getRedirectInfo() &&
                    window.optimizely.get('state').getRedirectInfo().referrer;
                if (optimizelyWebRedirectExperimentReferrer) {
                    // override document referrer in Tealium UDO when running Optimizely Web redirect experiments
                    referrer = optimizelyWebRedirectExperimentReferrer;
                }
            }

            tealiumUDO.referrer = referrer;

            tealiumUDO.pageViewFired = true;

            let firedTealiumUtagViewEvent = false;
            const fireTealiumUtagViewEventInterval = setInterval(() => {
                firedTealiumUtagViewEvent = fireTealiumUtagViewEvent(
                    tealiumUDO,
                    fireTealiumUtagViewEventInterval
                );
            }, 500);

            setTimeout(() => {
                if (!firedTealiumUtagViewEvent) {
                    console.error(
                        'fireTealiumUtagViewEvent - TIMED OUT: Tealium utag.js still not loaded after 60s.'
                    );
                    clearInterval(fireTealiumUtagViewEventInterval);
                }
            }, 60000);
        })
        .catch((error) => console.error('Error:', error));
};
