module.exports = {
    us: {
        currency: 'USD',
        currencyWithSymbol: 'USD ($)',
    },
    au: {
        currency: 'AUD',
        currencyWithSymbol: 'AUD (A$)',
    },
    ca: {
        currency: 'CAD',
        currencyWithSymbol: 'CAD (CA$)',
    },
    co: {
        currency: 'USD', // CTCT-specific requirement - set currency to USD
        currencyWithSymbol: 'USD ($)',
    },
    fr: {
        currency: 'EUR',
        currencyWithSymbol: 'EUR (€)',
    },
    de: {
        currency: 'EUR',
        currencyWithSymbol: 'EUR (€)',
    },
    in: {
        currency: 'INR',
        currencyWithSymbol: 'INR (₹)',
    },
    mx: {
        currency: 'MXN',
        currencyWithSymbol: 'MXN (MX$)',
    },
    nz: {
        currency: 'NZD', // CTCT-specific requirement - set currency to USD
        currencyWithSymbol: 'NZD (NZ$)',
    },
    es: {
        currency: 'EUR',
        currencyWithSymbol: 'EUR (€)',
    },
    gb: {
        currency: 'GBP',
        currencyWithSymbol: 'GBP (£)',
    },
    en: {
        currency: 'USD',
        currencyWithSymbol: 'USD ($)',
    },
};
