/* eslint no-useless-escape: 0, no-control-regex: 0, jsx-a11y/anchor-is-valid: 0, jsx-a11y/click-events-have-key-events: 0, jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';
import { invert, noop, isEqual } from 'lodash';
import Cookies from 'universal-cookie';
import {
    isValidHttpUrl,
    getAbsoluteUrl,
    getHostEnv,
    getURLComponents,
    itemizeSufCookieValues,
    getQueryParamVal,
    trimString,
} from '../../assets/scripts/helpers';
import { callFetch } from '../../assets/scripts/helpers/helpers-form';
import memoizeFn from '../../assets/scripts/helpers/memoizeFn';

const libphonenumber = require('google-libphonenumber');

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
const defaultMissingFieldMessage = 'This field is required.';

const rg4js = typeof window !== 'undefined' ? require('raygun4js') : null;

export const provisionErrorsMap = {
    'duplicate-username':
        'Looks like you already have an account with us!<br /><a href="/login?ic=suferr_email_login" rel="nofollow">Log in</a> or sign up using a different email address.',
    'invalid-login-name-match-password':
        'Your username and password cannot be the same. Please go back and set a different password.',
    'invalid-country-code':
        'Something went wrong. Please try again or call us at 855-783-2308 to set up your account.',
    'invalid-password':
        'Your password has not passed all validation requirements. Please enter a valid password.',
    'invalid-password-strength':
        'Your password has not passed all validation requirements. Please enter a Fair or Strong Password.',
    'invalid-login-name-special-characters':
        'Please check your username/email address and try again. It appears to contain unsupported special characters.',
    'invalid-referring-IP':
        'Something went wrong. Please try again or call us at 855-783-2308 to set up your account.',
    'invalid-partner':
        'Something went wrong. Please try again or call us at 855-783-2308 to set up your account.',
    'invalid-prohibited-country':
        'You are located in an embargoed country and we cannot create your account.',
    'invalid-email-special-characters':
        'Please check your username/email address and try again. It appears to contain unsupported special characters.',
    'length-promocode-too-big':
        'Something went wrong. Please try again or call us at 855-783-2308 to set up your account.',
    'invalid-state-code':
        'Something went wrong. Please try again or call us at 855-783-2308 to set up your account.',
    'missing-partner':
        'Something went wrong. Please try again or call us at 855-783-2308 to set up your account.',
    others: 'Something went wrong. Please try again or call us at 855-783-2308 to set up your account.',
};

export const getFormPayloadObjForRaygun = (postData) => {
    try {
        const formPayloadObjForRaygun = postData?.body
            ? JSON.parse(postData.body)
            : {};

        if (formPayloadObjForRaygun?.newPassword) {
            delete formPayloadObjForRaygun.newPassword;
        }

        return formPayloadObjForRaygun;
    } catch (error) {
        return {};
    }
};

const inputErrorMap = {
    familyName: 'Last Name',
    givenName: 'First Name',
    organization: 'Organization',
};

export const generateErrorMessage = (error, errorOccursOnSubmit = false) => {
    try {
        let errorMessage = { message: '' };

        if (
            error?.errorsMap &&
            typeof error.errorsMap === 'object' &&
            error.errorsMap !== null
        ) {
            for (const prop in error.errorsMap) {
                if (
                    error.errorsMap[prop] &&
                    Array.isArray(error.errorsMap[prop])
                ) {
                    errorMessage = error.errorsMap[prop].reduce(
                        (acc, next) => {
                            if (!acc.props.includes(prop)) {
                                const message =
                                    next ===
                                    'Email is a required field (cannot be empty).'
                                        ? 'This field is required.'
                                        : next;
                                acc.props = acc.props.concat(prop);
                                if (errorOccursOnSubmit) {
                                    const field = inputErrorMap[prop]
                                        ? inputErrorMap[prop]
                                        : prop;
                                    acc.message = acc.message.concat(
                                        `${field}: ${message}\r`
                                    );
                                } else {
                                    acc.message = acc.message.concat(
                                        `${message}\r`
                                    );
                                }
                            }
                            return acc;
                        },
                        { props: [], message: '' }
                    );
                }
            }
        }

        return errorMessage?.message || '';
    } catch (e) {
        return 'Something went wrong. Please try again or call us at 855-783-2308 to set up your account.';
    }
};

export const magicFunnelPostMessage = ({ type, payload = {} }) => {
    if (typeof window !== 'undefined') {
        try {
            const hostEnv = getHostEnv(window.location.hostname);
            window.parent.postMessage(
                {
                    type,
                    payload,
                },
                `https://app.${hostEnv}constantcontact.com/`
            );
        } catch (error) {
            if (window?.console) {
                console.warn(error);
            }
        }
    }
};

export const handleProvisionErrors = (
    formTypeTag,
    contentfulEntryUrl,
    httpStatus,
    response,
    setServerErrorCb,
    postData = {},
    onSubmitError = false,
    isMagicFunnelSignupForm = false
) => {
    // debugger;
    const provisionStatus = response?.provisionStatus || '';
    const provisionValidationErrorKey =
        response?.provisionValidationErrorKey || '';

    if (
        provisionValidationErrorKey &&
        provisionErrorsMap?.[provisionValidationErrorKey]
    ) {
        setServerErrorCb(provisionErrorsMap?.[provisionValidationErrorKey]);

        if (isMagicFunnelSignupForm) {
            magicFunnelPostMessage({
                type: 'AccountCreationError',
                payload: {
                    friendlyMessage:
                        provisionErrorsMap?.[provisionValidationErrorKey],
                    realError: provisionValidationErrorKey,
                    code: httpStatus,
                },
            });
        }
    } else if (response?.errorsMap && httpStatus !== 400) {
        const errorMessage =
            generateErrorMessage(response) || provisionErrorsMap.others;
        setServerErrorCb(errorMessage);

        if (isMagicFunnelSignupForm) {
            magicFunnelPostMessage({
                type: 'AccountCreationError',
                payload: {
                    friendlyMessage: errorMessage,
                    realError: provisionValidationErrorKey,
                    code: httpStatus,
                },
            });
        }
    } else if (
        response?.errorsMap &&
        !response?.errorsMap?.email &&
        httpStatus === 400
    ) {
        const errorMessage =
            generateErrorMessage(response, true) || provisionErrorsMap.others;
        setServerErrorCb(errorMessage);

        if (isMagicFunnelSignupForm) {
            magicFunnelPostMessage({
                type: 'AccountCreationError',
                payload: {
                    friendlyMessage: errorMessage,
                    realError: provisionValidationErrorKey,
                    code: httpStatus,
                },
            });
        }
    } else {
        const errorMessage = response?.errorsMap?.email
            ? generateErrorMessage(response, false)
            : provisionErrorsMap.others;

        setServerErrorCb(errorMessage);

        if (isMagicFunnelSignupForm) {
            magicFunnelPostMessage({
                type: 'AccountCreationError',
                payload: {
                    friendlyMessage: errorMessage,
                    realError: provisionValidationErrorKey,
                    code: httpStatus,
                },
            });
        }
    }

    const responseStatusTag = `response-status-${httpStatus}`;
    const formPayloadObjForRaygun = getFormPayloadObjForRaygun(postData);

    if (typeof window !== 'undefined' && rg4js) {
        // debugger;
        const otherErrorStatuses = [
            'CONNECTION_TIMED_OUT',
            'READ_TIMED_OUT',
            'FAILURE',
            'SUCCESS_OFFLINE',
        ];

        if (provisionStatus === 'VALIDATION_FAILURE') {
            // debugger;
            rg4js('send', {
                error: `provisionStatus = ${provisionStatus}, provisionValidationErrorKey = ${provisionValidationErrorKey}`,
                tags: [
                    'error-signup-api',
                    formTypeTag,
                    `provision-status-${provisionStatus
                        .toLowerCase()
                        .replaceAll('_', '-')}`,
                    `provision-validation-error-key-${provisionValidationErrorKey}`,
                ],
                customData: {
                    contentfulEntryUrl,
                    provisionStatus,
                    provisionValidationErrorKey,
                },
            });
        } else if (
            provisionStatus &&
            otherErrorStatuses.includes(provisionStatus)
        ) {
            // debugger;
            rg4js('send', {
                error: `provisionStatus = ${provisionStatus}`,
                tags: [
                    'error-signup-api',
                    formTypeTag,
                    `provision-status-${provisionStatus
                        .toLowerCase()
                        .replaceAll('_', '-')}`,
                ],
                customData: {
                    contentfulEntryUrl,
                    formPayloadObjForRaygun,
                    provisionStatus,
                },
            });
        } else if (httpStatus === 400) {
            // debugger;
            const tags = ['error-signup-api', formTypeTag, responseStatusTag];

            if (
                response?.errorsMap &&
                typeof response.errorsMap &&
                response.errorsMap !== null
            ) {
                for (const prop in response?.errorsMap) {
                    tags.push(`signup-api-validation-error-${prop}`);
                }
                // debugger;

                rg4js('send', {
                    error: 'form - onFormSubmit(...) - HTTP 400 error',
                    tags,
                    customData: {
                        contentfulEntryUrl,
                        errorsMap: response.errorsMap,
                        formPayloadObjForRaygun,
                    },
                });
            }
        } else if (onSubmitError) {
            // debugger;
            rg4js('send', {
                error: `form - onFormSubmit(...) - error: status = 
                    ${httpStatus}`,
                tags: ['error-form-submission', formTypeTag, responseStatusTag],
                customData: {
                    contentfulEntryUrl,
                    formPayloadObjForRaygun,
                    responseObj: response,
                },
            });
        } else if (!provisionStatus) {
            // debugger;
            rg4js('send', {
                error: 'provisionStatus is empty',
                tags: [
                    'error-signup-api',
                    formTypeTag,
                    'provision-status-empty',
                ],
                customData: {
                    contentfulEntryUrl,
                    formPayloadObjForRaygun,
                    responseObj: response,
                },
            });
        } else if (!httpStatus || !response) {
            /* this happens on a total server response fail */
            // debugger;
            rg4js('send', {
                error: 'provisionStatus is empty',
                tags: [
                    'error-signup-api',
                    formTypeTag,
                    'provision-status-empty',
                ],
                customData: {
                    contentfulEntryUrl,
                    formPayloadObjForRaygun,
                    responseObj: {},
                },
            });
        }
    }
};

export const handleApiFailure = (
    formTypeTag,
    contentfulEntryUrl,
    setServerErrorCb,
    postData = {},
    errorData
) => {
    setServerErrorCb(provisionErrorsMap.others);
    const formPayloadObjForRaygun = getFormPayloadObjForRaygun(postData);

    if (typeof window !== 'undefined' && rg4js) {
        rg4js('send', {
            error: `form - onFormSubmit(...) - error: apiFailure`,
            tags: [
                'error-form-submission',
                formTypeTag,
                `${formTypeTag}ApiFailure`,
            ],
            customData: {
                contentfulEntryUrl,
                formPayloadObjForRaygun,
                errorData,
            },
        });
    }
};

export const strHasValue = (value) =>
    value && typeof value === 'string' && value?.trim() !== '';

export const prohibitedEmails = ['ir', 'kp', 'cu', 'sy', 'crimea.ua', 'cr.ua'];

const defaultFailureMessage =
    "Please check that your email follows the xxx@yyy.zzz format and doesn't contain spaces or special characters.";

const disallowedEmailUsernames = [
    'all',
    'everyone',
    'ftp',
    'hostmaster',
    'investorrelations',
    'jobs',
    'marketing',
    'media',
    'noc',
    'noreply',
    'no-reply',
    'postmaster',
    'prime',
    'privacy',
    'remove',
    'request',
    'root',
    'sales',
    'security',
    'uce',
    'usenet',
    'uucp',
    'webmaster',
    'www',
];

const disallowedEmailUsernameParts = [
    'abuse',
    'admin',
    'esponder',
    'interest',
    'listadmin',
    'listserv',
    'serviceadmin',
    'spam',
    'subscribe',
    'users',
];

const disallowedEmailHosts = [
    'craigslist.org',
    'googlegroups.com',
    'yahoogroups.com',
];

const roleBasedAddressValidationFailureMessage = `
            This email address cannot be used. Learn more about
            <a
                href="https://knowledgebase.constantcontact.com/email-digital-marketing/articles/KnowledgeBase/5538-about-role-addresses-group-addresses-and-aliases?lang=en_US"
                rel="noreferrer"
                target="_blank"
            >
                role email addresses
            </a>
            .
        `;

export const validateRoleBasedEmails = (email) => {
    try {
        const emailParts = email.split('@');

        if (
            (emailParts?.length === 2 &&
                disallowedEmailUsernames.includes(emailParts?.[0])) ||
            disallowedEmailUsernameParts?.some((x) =>
                emailParts?.[0]?.match(new RegExp(x, 'gi'))
            ) ||
            disallowedEmailHosts.includes(emailParts[1])
        ) {
            return true;
        }

        return false;
    } catch (error) {
        return false;
    }
};

export const validateEmail = (
    inputName,
    email,
    isRequired = false,
    errorMessages = {},
    failureMessage = defaultFailureMessage,
    missingMessage = defaultMissingFieldMessage
) => {
    if (errorMessages?.[inputName]) {
        /* this would come from the server */
        return errorMessages[inputName];
    }

    if (
        email &&
        strHasValue(email) &&
        prohibitedEmails.some((x) => email?.endsWith(x))
    ) {
        return 'Please include an email domain name with a geographical suffix that does not belong to a prohibited country.';
    }

    if (
        email &&
        strHasValue(email) &&
        (email?.length < 6 || email?.length > 50)
    ) {
        return 'Your email must be between 6 and 50 characters.';
    }

    if (
        email &&
        strHasValue(email) &&
        !email?.match(/^[A-Za-z0-9_@.\-\_\+/]*$/)
    ) {
        return failureMessage;
    }

    if (
        email &&
        String(email)
            ?.toLowerCase()
            ?.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
    ) {
        return '';
    }

    if (email) {
        return failureMessage;
    }

    return isRequired ? missingMessage : '';
};

export const validateTelNumberOnly = (
    tel,
    missingMessage = defaultMissingFieldMessage
) => {
    if (
        tel &&
        String(tel)?.match(
            /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
        )
    ) {
        return '';
    }

    if (tel) {
        return 'This is not a valid phone number.';
    }

    return missingMessage;
};

const testErrorResult = (validationResult) => {
    let isValid = false;

    switch (validationResult) {
        case 'IS_POSSIBLE':
            isValid = true;
            break;
        case 'IS_POSSIBLE_LOCAL_ONLY': // *Note: This is invalid for CTCT b/c we need to collect phone numbers in national (not local) format.
        case 'INVALID_COUNTRY_CODE':
        case 'INVALID_LENGTH':
        case 'TOO_SHORT':
        case 'TOO_LONG':
        default:
            isValid = false;
            break;
    }

    return isValid;
};

export const validateTel = (tel = '', countryCode = 'us', required) => {
    if (!required && !tel) {
        return '';
    }

    if (!tel && required) {
        return 'This field is required.';
    }

    if (!countryCode || !tel || (typeof tel === 'string' && tel?.length < 2)) {
        return validateTelNumberOnly(tel);
    }

    try {
        const phoneNumber = phoneUtil.parseAndKeepRawInput(
            tel,
            countryCode.toUpperCase()
        );

        /* 
            this is kind of a hack but it does what we want it to,
            essentially we are overriding Google's parsing function
            so that it does NOT strip the country code from the input 
        */
        const phoneNumberObject = {};
        const rawTelString = tel?.replace(/-/gi, '');
        for (const prop in phoneNumber) {
            if (prop === 'values_') {
                phoneNumberObject[prop] = {
                    ...phoneNumber[prop],
                    2: Number(rawTelString),
                };
            } else {
                phoneNumberObject[prop] = phoneNumber[prop];
            }
        }

        const isPossibleNumberWithReason =
            phoneUtil.isPossibleNumberWithReason(phoneNumberObject);

        const errorMap = libphonenumber?.PhoneNumberUtil?.ValidationResult
            ? invert(libphonenumber.PhoneNumberUtil.ValidationResult)
            : {};

        const errorMessage = errorMap?.[isPossibleNumberWithReason] || null;
        const isValidNumberForRegion =
            isPossibleNumberWithReason && errorMessage
                ? testErrorResult(errorMessage)
                : true;

        const countryCodeErrorMap = {
            us: 'This is not a valid phone number for this location. Please enter a 10-digit number.',
            ca: 'This is not a valid phone number for this location. Please enter a 10-digit number.',
            gb: 'This is not a valid phone number for this location. Please enter a valid phone number with 7, 9, 10, or 11 digits.',
        };

        if (!isValidNumberForRegion) {
            return countryCodeErrorMap?.[countryCode]
                ? countryCodeErrorMap[countryCode]
                : 'This is not a valid phone number for this location.';
        }

        return '';
    } catch (err) {
        if (typeof err?.message === 'string') {
            return err.message;
        }

        return tel
            ? 'Please check your information and try again.'
            : 'This field is required.';
    }
};

export const validateTelOnCountryChange = (
    inputName,
    tel,
    country,
    required,
    setErrorCb = noop
) => {
    const errorMessage = validateTel(tel, country, required);

    if (setErrorCb && typeof errorMessage === 'string') {
        setErrorCb(inputName, errorMessage);
    }
};

export const formatValue = (value, type, name) => {
    if (type === 'text' && value && typeof value === 'string') {
        return value.replace(/’/gi, "'");
    }

    if (type === 'tel' && value && typeof value === 'string') {
        return value.replace(/[^0-9\-]/g, '').replace(/\-\-/, '-');
    }

    if (type === 'password' && value && typeof value === 'string') {
        return value.replace(/\s{2,}/g, ' ');
    }

    return value;
};

export const getStatusClass = (
    fields = {},
    inputName = '',
    showErrorState = true
) =>
    fields?.[inputName]?.error && showErrorState
        ? 'error'
        : fields?.[inputName]?.value &&
          !fields?.[inputName]?.focused &&
          !fields?.[inputName]?.error
        ? 'valid'
        : '';

export const mandatoryProvisionInputs = [
    /**
     * These are mandatory inputs for our provisioning form.
     * If/when adding new inputs, the data MUST be structured as shown below,
     * so as to mimic Contentful data, and so that the fields are rendered
     * properly.
     */
    {
        contentful_id: 'constantContact-mock-contentfulId-08012022-1',
        id: 'constantContact-mandatory-fieldId-08012022-1',
        inputLabel: null,
        inputName: 'currency',
        inputRequired: null,
        inputType: 'hidden',
        inputValue: null,
        placeholderText: null,
        submitButtonColor: null,
        submitButtonWidth: null,
        __typename: 'ContentfulFormInput',
    },
    {
        contentful_id: 'constantContact-mock-contentfulId-08012022-2',
        id: 'constantContact-mandatory-fieldId-08012022-2',
        inputLabel: null,
        inputName: 'provisioning',
        inputRequired: null,
        inputType: 'hidden',
        inputValue: 'true',
        placeholderText: null,
        submitButtonColor: null,
        submitButtonWidth: null,
        __typename: 'ContentfulFormInput',
    },
];

export const serializeString = (value) => {
    // encode newlines as \r\n cause the html spec says so
    value = value.replace(/(\r)?\n/g, '\r\n');
    value = encodeURIComponent(value);

    // spaces should be '+' rather than '%20'.
    value = value.replace(/%20/g, '+');
    return value;
};

export const getRequiredStylingJSX = (required, requiredStyling, inputType) =>
    (required && requiredStyling === 'Emphasize Required') ||
    (inputType === 'tel' && required && requiredStyling === undefined) ? (
        <span className="field__required"> *</span>
    ) : !required && requiredStyling === 'Emphasize Optional' ? (
        ' (Optional)'
    ) : (
        ''
    );

export const getRequiredMUIStylingJSX = (required, requiredStyling, label) => {
    if (!required && requiredStyling === 'Emphasize Optional') {
        return `${label} (Optional)`;
    }

    return label;
};

// export const domainRegex =
//     /^(((http|https):\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;
export const domainRegex =
    /^(((http|https):\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;

export function formatUrl(url) {
    if (url.startsWith('http://') || url.startsWith('https://')) {
        return url;
    }

    return `https://${url}`;
}

export function isUrlValid(url) {
    const hasValue = strHasValue(url);

    if (hasValue && url?.toLowerCase().match(domainRegex)) {
        return true;
    }

    return false;
}

export const validateTextInput = (inputName, value, required, type, fields) => {
    const hasValue = strHasValue(value);
    const permissiveValidation = [
        'givenName',
        'familyName',
        'organization',
        'ccUsername',
    ];

    const isURLField = fields?.[inputName]?.label?.match(/Website/gi);

    if (hasValue && (inputName === 'url' || isURLField)) {
        if (formatUrl(value)?.toLowerCase()?.match(domainRegex)) {
            return '';
        }

        return 'Please check your URL formatting';
    }

    if (
        hasValue &&
        !permissiveValidation.includes(inputName) &&
        value?.match(/[#%^*_+\=\[\]{}\\|<>\/]+/)
    ) {
        return 'This field contains invalid characters.';
    }

    if (hasValue && permissiveValidation.includes(inputName)) {
        /* prevent non-english characters */
        if (value?.match(/[^\u0000-\u007F]+/)) {
            return 'This field contains invalid characters.';
        }

        /* allow everything but brackets, curly brances and ampersands */
        if (
            (inputName === 'givenName' || inputName === 'familyName') &&
            value?.match(/<|>|{|}|"|&/)
        ) {
            return 'This field contains invalid characters.';
        }

        if (inputName === 'organization' && value?.match(/<|>|{|}|"/)) {
            /* for organizations, we allow ampersands */
            return 'This field contains invalid characters.';
        }
    }

    if (hasValue && value?.length > 80 && type !== 'textarea') {
        return 'Must be less than or equal to 80 characters.';
    }

    if (!hasValue && required) {
        return 'This field is required.';
    }

    return '';
};

export const validatePassword = (
    inputName,
    value,
    fields,
    isSubmitAttempt = false
) => {
    const email = fields?.email?.value;
    const usePasswordStrengthMeter =
        fields?.[inputName]?.usePasswordStrengthMeter || false;
    const passwordStrength = fields?.[inputName]?.passwordStrengthLevel || null;
    const validPasswordStrengthLevels = ['fair', 'good', 'strong'];
    const hasValue = strHasValue(value);

    if (
        isSubmitAttempt &&
        fields?.[inputName]?.error &&
        fields?.[inputName]?.error?.match(/safe choice/gi)
    ) {
        return fields?.[inputName]?.error;
    }

    if (hasValue && value?.match(/[^\u0000-\u007F]+/)) {
        /* prevent non-english characters */
        return 'This field contains invalid characters.';
    }

    if (usePasswordStrengthMeter) {
        if (value && typeof value === 'string' && value?.trim() === '') {
            return 'This field is required.';
        }

        if (hasValue && !(value?.length >= 8 && value?.length <= 80)) {
            return 'Password must be between 8 and 80 characters.';
        }

        if (hasValue && email === value) {
            return 'Password cannot match your email.';
        }

        if (value && !validPasswordStrengthLevels.includes(passwordStrength)) {
            return "That's not the safest choice. Please pick another.";
        }

        if (!hasValue) {
            return 'This field is required.';
        }
    } else {
        if (
            // if password field has value and is not between 8 and 80 characters
            hasValue &&
            !(value?.length >= 8 && value?.length <= 80)
        ) {
            return 'Password must be between 8 and 80 characters.';
        }

        if (hasValue && email === value) {
            return 'Password cannot match your email.';
        }

        if (!hasValue) {
            return 'This field is required.';
        }
    }

    return '';
};

export const addConditionalComponentProps = (
    inputs,
    comparator = 'conditionalKey'
) =>
    inputs.reduce((acc, next) => {
        if (next?.[comparator] && next?.components) {
            const { components, ...rest } = next;
            acc = acc.concat(
                components.reduce((a, n) => {
                    const { components: nestedComponents, ...other } = n;
                    const componentsData =
                        nestedComponents && Array.isArray(nestedComponents)
                            ? addConditionalComponentProps(
                                  nestedComponents,
                                  'conditionalValue'
                              )
                            : [];

                    if (componentsData && Array.isArray(componentsData)) {
                        for (let i = 0; i < componentsData.length; i++) {
                            a = a.concat({
                                ...rest,
                                ...other,
                                ...componentsData[i],
                            });
                        }
                    } else {
                        a = a.concat({
                            ...rest,
                            ...other,
                        });
                    }
                    return a;
                }, [])
            );
        } else {
            acc = acc.concat(next);
        }
        return acc;
    }, []);

export const getSOParts = (str) => {
    try {
        return str?.split(',')?.reduce((acc, next) => {
            const parts = next?.split(':');
            if (parts?.[0] && parts?.[1]) {
                acc[parts?.[0]] = parts?.[1]?.trim();
            }
            return acc;
        }, {});
    } catch (error) {
        return {
            CTCT_Website_Signup_Origin: 'signup-api',
            CTCT_Website_Signup_Flow: 'try',
            CTCT_PROVISION_ORIGIN: 'Website',
            CTCT_WEBSITE_DEVICE_TYPE: 'desktop',
        };
    }
};

export const generateSiteOwnerPropsPostData = (
    cookies,
    val = '{}',
    socialProvider = '',
    isRecaptureForm = false
) => {
    const deviceType = cookies.get('ctct-device-type');
    let postData;

    try {
        if (/<|>/.test(val)) {
            val = val.replace('<', '');
            val = val.replace('>', '');
        }

        postData = JSON.parse(val);
    } catch (error) {
        postData = {};
    }

    postData = {
        ...postData,
        CTCT_PROVISION_ORIGIN: socialProvider
            ? `website_signup-${socialProvider}`
            : 'Website',
        CTCT_WEBSITE_DEVICE_TYPE: deviceType,
    };

    const isTrialFlow = postData?.CTCT_Website_Signup_Flow === 'try';

    if (socialProvider && (isTrialFlow || isRecaptureForm)) {
        postData = {
            ...postData,
            CTCT_WEBSITE_BYPASS_BUY_ABANDON_FORM: isRecaptureForm,
        };
    }

    const arr = [];
    for (const [key, value] of Object.entries(postData)) {
        arr.push(`${key}: ${value}`);
    }

    return arr && arr?.length ? arr.join(',') : '';
};

export const handleInternationalDisountPricing = (
    cookies,
    postParams,
    isAutoDeployForm,
    isContactForm
) => {
    try {
        const countryCode = cookies.get('ctct-geolocated-country-code');
        const applicableCountries = ['au', 'gb', 'nz'];
        const isAnyAutoDeployPayload = postParams?.siteOwnerProperties
            ? getSOParts(
                  postParams.siteOwnerProperties
              )?.CTCT_Website_Signup_Flow?.match(/auto-deploy|partner/)
            : false;

        if (
            isAutoDeployForm ||
            isAnyAutoDeployPayload ||
            isContactForm ||
            !applicableCountries.includes(countryCode)
        ) {
            return postParams;
        }

        const discountPricingCodes = {
            au: 'AUSTRALIAPRICINGV1',
            gb: 'UKPRICEV1',
            nz: 'NZEALANDV1',
        };

        if (
            discountPricingCodes[countryCode] &&
            applicableCountries.includes(countryCode)
        ) {
            return {
                ...postParams,
                offerCode: discountPricingCodes[countryCode],
            };
        }

        return postParams;
    } catch (err) {
        return postParams;
    }
};

export const ensureEmailVerificationFieldQueryStringSet = (url) => {
    try {
        const { search } = new URL(url);
        if (search && search?.match(/emailVerified/)) {
            return url;
        }

        if (search) {
            return `${url}&emailVerified=true`;
        }

        return `${url}?emailVerified=true`;
    } catch (error) {
        return url;
    }
};

export const handleEmailVerification = (
    cookies,
    postParams,
    isAutoDeployForm,
    isContactForm,
    emailVerificationDestinationUrl
) => {
    try {
        const signupEmailVerificationCookie = cookies.get(
            'ctct-signup-email-verification-flow'
        );

        const isRoleBasedEmail =
            postParams.email && validateRoleBasedEmails(postParams.email);

        if (
            signupEmailVerificationCookie &&
            !isAutoDeployForm &&
            !isContactForm &&
            window?.location?.pathname?.match(/signup/) &&
            !window?.location?.pathname?.match(/buynow/) &&
            !isRoleBasedEmail
        ) {
            let resolvedEmailVerificationDestinationUrl = getAbsoluteUrl(
                emailVerificationDestinationUrl ||
                    'https://app.constantcontact.com/pages/dashboard/home?emailVerified=true'
            );

            resolvedEmailVerificationDestinationUrl =
                ensureEmailVerificationFieldQueryStringSet(
                    resolvedEmailVerificationDestinationUrl
                );

            const accountSettings = `[{"applicationId": "signin", "settingName": "signup-email-verification-flow-completed", "settingValue": "false"},{"applicationId": "signin", "settingName": "signup-email-verification-flow-goto", "settingValue": "${encodeURIComponent(
                resolvedEmailVerificationDestinationUrl
            )}"}]`;

            return {
                ...postParams,
                accountSettings,
                sendProvisionLetters: 'false',
            };
        }

        return postParams;
    } catch (error) {
        return postParams;
    }
};

export const addV2PricingPayloadData = (postParams, v2PricingData) => {
    try {
        let accountSettings = postParams?.accountSettings
            ? JSON.parse(postParams.accountSettings)
            : [];

        accountSettings = JSON.stringify([
            ...accountSettings,
            {
                application_id: 'myaccount',
                setting_name: 'v2-pricing-experience',
                setting_value: 'true',
            },
        ]);

        return {
            ...postParams,
            currency: v2PricingData.currency,
            contactCount: v2PricingData.contactCount,
            planType: v2PricingData.planType,
            paymentPeriod: v2PricingData.paymentPeriod,
            nonprofit: v2PricingData.nonProfit,
            provisioning: true,
            accountSettings,
        };
    } catch (error) {
        return postParams;
    }
};

export const leadMagnetPayloadData = (
    cookies,
    postParams,
    isAutoDeployForm,
    isContactForm
) => {
    try {
        const leadMagnetCookie = cookies.get('contactcapture');
        let accountSettings = postParams?.accountSettings
            ? JSON.parse(postParams.accountSettings)
            : [];
        if (leadMagnetCookie && !isAutoDeployForm && !isContactForm) {
            accountSettings = JSON.stringify([
                ...accountSettings,
                {
                    application_id: 'rise',
                    setting_name: 'enable_lead_magnet',
                    setting_value: 'true',
                },
            ]);
            return {
                ...postParams,
                provisioning: true,
                accountSettings,
            };
        }
        return postParams;
    } catch (error) {
        return postParams;
    }
};

export const handleMissingPostParams = (
    cookies,
    postParams = {},
    formInputs = [],
    isContactForm = false,
    isAutoDeployForm = false,
    emailVerificationDestinationUrl = '',
    v2PricingData = null
) => {
    if (!postParams.tel && !isAutoDeployForm) {
        postParams = {
            ...postParams,
            tel: '2222222222',
        };
    }

    if (!postParams.country && !isContactForm) {
        postParams = {
            ...postParams,
            country:
                itemizeSufCookieValues(cookies.get('suf-values'))
                    ?.siteContactCountryCode ||
                cookies.get('ctct-geolocated-country-code') ||
                'us',
        };
    }

    /* do this stuff with states and provinces to prevent API errors */
    if (
        !isContactForm &&
        postParams?.country &&
        postParams?.country?.toLowerCase() !== 'us' &&
        postParams?.hasOwnProperty('state')
    ) {
        delete postParams.state;
    }

    if (
        !isContactForm &&
        postParams?.country &&
        postParams?.country?.toLowerCase() === 'us' &&
        postParams?.state?.length !== 2
    ) {
        delete postParams.state;
    }

    if (
        !isContactForm &&
        postParams?.country &&
        postParams?.country?.toLowerCase() !== 'ca' &&
        postParams?.hasOwnProperty('province')
    ) {
        delete postParams.province;
    }

    if (
        !isContactForm &&
        postParams?.country &&
        postParams?.country?.toLowerCase() === 'ca' &&
        postParams?.province?.length !== 2
    ) {
        delete postParams.province;
    }

    const partnerCookie = cookies.get('cclp_session_partner');

    if (
        !isContactForm &&
        (!postParams?.partnerName ||
            (postParams?.partnerName &&
                typeof postParams?.partnerName !== 'string') ||
            (postParams?.partnerName &&
                partnerCookie &&
                postParams?.partnerName !== partnerCookie))
    ) {
        /* 
            CTCTFOS-21729: this is simply additional bulletproofing,
            the value should be reliably set by the getHiddenInputValue
            function in form-input-view.jsx
        */

        let partnerName = 'ROVING';
        if (partnerCookie && typeof partnerCookie === 'string') {
            partnerName = partnerCookie;
        } else {
            partnerName = 'ROVING';
        }

        postParams = {
            ...postParams,
            partnerName,
        };
    }

    if (
        !isContactForm &&
        postParams?.siteOwnerProperties &&
        !postParams?.familyName
    ) {
        if (
            !getSOParts(postParams.siteOwnerProperties)
                ?.CTCT_WEBSITE_BYPASS_BUY_ABANDON_FORM
        ) {
            postParams = {
                ...postParams,
                familyName:
                    getSOParts(postParams.siteOwnerProperties)
                        ?.CTCT_Website_Signup_Flow === 'try'
                        ? 'LastName'
                        : 'LastNameBN',
            };
        }
    }

    /* explicitly set partnerName if it is set in Contentful */
    const hardcodedPartnerName =
        formInputs && Array.isArray(formInputs)
            ? formInputs.find(
                  (x) => x?.inputName && x?.inputName === 'partnerName'
              )?.inputValue || null
            : null;

    if (hardcodedPartnerName) {
        postParams = {
            ...postParams,
            partnerName: hardcodedPartnerName,
        };
    }

    /* handle email verification flow */
    postParams = handleEmailVerification(
        cookies,
        postParams,
        isAutoDeployForm,
        isContactForm,
        emailVerificationDestinationUrl
    );

    postParams = handleInternationalDisountPricing(
        cookies,
        postParams,
        isAutoDeployForm,
        isContactForm
    );

    postParams = leadMagnetPayloadData(
        cookies,
        postParams,
        isAutoDeployForm,
        isContactForm
    );

    if (v2PricingData && !isAutoDeployForm && !isContactForm) {
        postParams = addV2PricingPayloadData(postParams, v2PricingData);
    }

    return postParams;
};

export const getBuyFlowFieldPairs = (
    fields = {},
    destinationUrl,
    v2PricingData
) => {
    if (typeof window === 'undefined') {
        return getURLComponents(destinationUrl);
    }

    const currentAddress = window.location.href;
    const { port, protocol } = new URL(currentAddress);

    /* double ternary tests for malformed URLs */
    const url = isValidHttpUrl(destinationUrl)
        ? destinationUrl
        : isValidHttpUrl(`${protocol}//${destinationUrl}`)
        ? `${protocol}//${destinationUrl}`
        : destinationUrl;
    const urlWithParams = getURLComponents(url);
    const params = urlWithParams.searchParams;

    // these are all the buy flow field names that we're using on provisioning forms
    const coreBuyflowFields = [
        'promotionCode',
        'planType',
        'selectedPlan',
        'currency',
        'showNonprofitCheckbox',
        'prepay',
        'provisioning',
        'successInIframe',
        'contactCount',
        'paymentPeriod',
        'nonProfit',
        'promotionCd',
    ];
    const buyFlowFields = v2PricingData
        ? [...coreBuyflowFields, 'partner_name', 'buyNowVersion']
        : coreBuyflowFields;

    if (
        v2PricingData &&
        typeof v2PricingData === 'object' &&
        Object.keys(v2PricingData).length
    ) {
        /* new pricing form data routine */
        for (const prop in v2PricingData) {
            if (buyFlowFields.includes(prop)) {
                const key = prop === 'nonProfit' ? 'nonprofit' : prop;
                if (prop === 'promotionCd') {
                    if (v2PricingData[prop]) {
                        params.append(key, v2PricingData[prop]);
                    }
                } else {
                    params.append(key, v2PricingData[prop]);
                }
            }
        }
    } else {
        // map through all the form inputs and if any buy flow fields exist and have values, build an object out of them, so that we can create query strings
        for (const prop in fields) {
            if (buyFlowFields?.includes(prop) && fields?.[prop]?.value) {
                params.append(prop, fields[prop].value);
            }
        }
    }

    if (port && urlWithParams?.hostname !== 'app.l1.constantcontact.com') {
        urlWithParams.port = port;
    }

    return urlWithParams;
};

/* memoize functions with complex args */
const comparators = [isEqual];

export const getDestinationUrl = memoizeFn(
    (destinationPageUrl, fields = {}, isEmailVerification) => {
        const params =
            typeof window !== 'undefined' ? window.location.search : '';
        const originalUrlParam = new URLSearchParams(params.toLowerCase()).get(
            'originalurl'
        );
        const goToUrlParam = new URLSearchParams(params.toLowerCase()).get(
            'gotourl'
        );

        const originalUrlField = fields?.goToUrl?.value || '';
        const goToUrlField = fields?.originalUrl?.value || '';

        const goToUrl = goToUrlParam || goToUrlField;
        const originalUrl = originalUrlParam || originalUrlField;
        let returnDestinationUrl = destinationPageUrl;

        // (contentful “Thank You/Destination Url” field) should be the default but is trumped by goToUrl and OriginalUrl
        if (goToUrl) {
            // (query param/field) trumps destination url but is trumped by original url
            returnDestinationUrl = goToUrl;
        }
        if (originalUrl) {
            // (query param/field) trumps both goToURL and destination url
            returnDestinationUrl = originalUrl;
        }

        let thisReturnDestinationUrl = destinationPageUrl;

        try {
            thisReturnDestinationUrl = getAbsoluteUrl(returnDestinationUrl);
        } catch (error) {
            thisReturnDestinationUrl = destinationPageUrl;
        }

        // Should we default this to a return url if no url is present?
        if (!thisReturnDestinationUrl) {
            thisReturnDestinationUrl = isEmailVerification
                ? 'https://app.constantcontact.com/pages/dashboard/home?emailVerified=true'
                : 'https://app.constantcontact.com/pages/dashboard/home/#/';
        }

        /* add a port number if we are in dev mode */
        thisReturnDestinationUrl =
            typeof window !== 'undefined' && window?.location?.port === '8000'
                ? thisReturnDestinationUrl.replace(
                      'ctctfos.l1.constantcontact.com/',
                      'ctctfos.l1.constantcontact.com:8000/'
                  )
                : thisReturnDestinationUrl;

        thisReturnDestinationUrl = thisReturnDestinationUrl?.match(
            /https:\/\/app.l1.constantcontact.com:8000|https:\/\/app.s1.constantcontact.com:8000|https:\/\/app.constantcontact.com:8000/
        )
            ? thisReturnDestinationUrl.replace(':8000', '')
            : thisReturnDestinationUrl;

        return thisReturnDestinationUrl;
    },
    { comparators }
);

export const displayPartner = memoizeFn(
    (partnerState, partnerFromBackEndState, suppressPnOption = null) => {
        // suppress personalization if CA has opted to do so
        const suppressPersonalizationOpts =
            partnerState?.personalizationSuppression;
        if (suppressPersonalizationOpts?.includes(suppressPnOption)) {
            return null;
        }
        // If there is a partner from Contentful display that partner data
        // If there is no partner from Contentful but the back end has data display that data
        if (partnerState && partnerState?.heading) {
            return partnerState.heading;
        }

        if (!partnerState && partnerFromBackEndState) {
            return `Constant Contact and ${partnerFromBackEndState} are working together to help you become a better marketer.`;
        }

        return null;
    },
    { comparators }
);

export const parseConditionalInputs = memoizeFn(
    (inputs, cookies, defaultLocation) =>
        inputs.reduce((acc, next) => {
            if (
                next?.conditionalKey &&
                next?.conditionalValue &&
                next?.conditionalType === 'Cookie'
            ) {
                const cookieValue = cookies.get(next.conditionalKey);
                if (cookieValue || defaultLocation) {
                    if (
                        next?.conditionalValue
                            ?.split('|')
                            ?.includes(cookieValue) ||
                        next?.conditionalValue
                            ?.split(',')
                            ?.includes(cookieValue) ||
                        (next?.conditionalKey ===
                            'ctct-geolocated-country-code' &&
                            defaultLocation &&
                            (next?.conditionalValue
                                ?.split(',')
                                ?.includes(defaultLocation) ||
                                next?.conditionalValue
                                    ?.split('|')
                                    ?.includes(defaultLocation)))
                    ) {
                        acc = acc.concat(next);
                    }
                } else {
                    acc = acc.concat(next);
                }
            } else if (
                next?.conditionalKey &&
                next?.conditionalValue &&
                next?.conditionalType === 'Url Parameter'
            ) {
                const search =
                    typeof window !== 'undefined'
                        ? window.location.search
                        : null;
                const value = search
                    ? getQueryParamVal(search, next.conditionalKey)
                    : '';
                if (value) {
                    if (
                        next?.conditionalValue?.split('|')?.includes(value) ||
                        next?.conditionalValue?.split(',')?.includes(value)
                    ) {
                        acc = acc.concat(next);
                    }
                } else {
                    acc = acc.concat(next);
                }
            } else {
                acc = acc.concat(next);
            }

            return acc;
        }, []),
    { comparators }
);

export const testVisibleInputErrors = (fields) =>
    Object?.keys(fields)?.reduce((acc, next) => {
        if (fields?.[next]?.error && fields?.[next]?.type !== 'hidden') {
            acc += 1;
        }
        return acc;
    }, 0);

export const isGoogleSSOFormStatePristine = memoizeFn(
    (
        formState,
        useGoogleSSO,
        inputsToMap = [],
        isSignupForm = false,
        displayAllFields = false
    ) => {
        if (useGoogleSSO && isSignupForm) {
            if (displayAllFields) {
                return {
                    reducedFields: inputsToMap,
                    isPristineGoogleSSOForm: false,
                };
            }

            const reducedFields = inputsToMap.reduce((acc, next, idx) => {
                if (idx === 0 || next?.inputType === 'hidden') {
                    acc = acc.concat(next);
                }

                return acc;
            }, []);

            const firstInputName = reducedFields?.[0]?.inputName || null;
            const firstInputHasValueOrDirty =
                formState?.fields?.[firstInputName]?.value ||
                formState?.fields?.[firstInputName]?.changed ||
                false;

            return {
                reducedFields,
                isPristineGoogleSSOForm: !firstInputHasValueOrDirty,
            };
        }

        return {
            reducedFields: inputsToMap,
            isPristineGoogleSSOForm: false,
        };
    },
    { comparators }
);

export const extendAccountSettingsData = (
    accountSettings,
    socialProvider = 'google'
) => {
    if (accountSettings && Array.isArray(accountSettings)) {
        return JSON.stringify([
            ...accountSettings,
            {
                applicationId: 'landingpages',
                settingName: 'ctct-provision-origin',
                settingValue: `website_signup-${socialProvider}`,
            },
        ]);
    }

    if (accountSettings && typeof accountSettings === 'string') {
        try {
            const arr = JSON.parse(accountSettings);
            if (arr && Array.isArray(arr)) {
                return JSON.stringify([
                    ...arr,
                    {
                        applicationId: 'landingpages',
                        settingName: 'ctct-provision-origin',
                        settingValue: `website_signup-${socialProvider}`,
                    },
                ]);
            }
        } catch (err) {
            return accountSettings;
        }
    }

    return accountSettings;
};

export const onClickMagicFunnelBackBtn = (e) =>
    magicFunnelPostMessage({
        type: 'GoBack',
        payload: null,
    });

export const magicFunnelLoginLinkJSX = (
    <p>
        Looks like you already have an account with us!{' '}
        <a
            onClick={(e) => {
                e.preventDefault();
                magicFunnelPostMessage({
                    type: 'FormLoginClick',
                    payload: null,
                });
            }}
            style={{ textDecoration: 'underline' }}
        >
            Log in
        </a>{' '}
        or sign up using a different email address.
    </p>
);

export const getRecaptureFormInputs = () => {
    const defaults = [
        {
            __typename: 'ContentfulFormInput',
            contentful_id: '3qZbBk4KvhrCgjrxWgNCE2',
            id: '1aeb024a-d29b-5d4a-89d3-ee42e7e7188b',
            inputType: 'text',
            inputName: 'givenName',
            inputRequired: ['Check if required'],
            inputLabel: 'First Name',
            inputValue: null,
            note: null,
            placeholderText: null,
            submitButtonColor: null,
            submitButtonWidth: null,
            usePasswordStrengthMeter: null,
            useTwoColumnLayout: null,
        },
        {
            __typename: 'ContentfulFormInput',
            contentful_id: '2WilHytbySBXPr9m9MeLff',
            id: 'e48df52f-05e0-5844-a220-80fda6ad8feb',
            inputType: 'text',
            inputName: 'familyName',
            inputRequired: ['Check if required'],
            inputLabel: 'Last Name',
            inputValue: null,
            note: null,
            placeholderText: null,
            submitButtonColor: null,
            submitButtonWidth: null,
            usePasswordStrengthMeter: null,
            useTwoColumnLayout: null,
        },
        {
            __typename: 'ContentfulFormInput',
            contentful_id: '7518kjrZDt4eA0oOAcWPa3',
            id: 'ca658d19-6e90-55d3-a077-57395d81b6ff',
            inputType: 'text',
            inputName: 'organization',
            inputRequired: ['Check if required'],
            inputLabel: 'Organization Name',
            inputValue: null,
            note: null,
            placeholderText: null,
            submitButtonColor: null,
            submitButtonWidth: null,
            usePasswordStrengthMeter: null,
            useTwoColumnLayout: null,
        },
        {
            __typename: 'ContentfulConditionalWrapper',
            contentful_id: '2gINJIsgqsQ217EkzBoAV',
            id: '09bd3e53-30e5-56a1-925a-b5d2846f604d',
            conditionalKey: 'ctct-geolocated-country-code',
            conditionalType: 'Cookie',
            components: [
                {
                    __typename: 'ContentfulConditionalComponent',
                    contentful_id: '3HP3dWflHJLQjk8sI7ykqZ',
                    id: '4fd05710-158d-5ebc-8403-6e0b862e70c7',
                    makeDefault: null,
                    conditionalValue:
                        'ag|ai|bb|bm|bs|ca|dm|do|gd|ie|jm|kn|ky|lc|mf|ms|mx|nl|pr|tc|tt|us|vc|vi|cl|br|gb|in|au',
                    components: [
                        {
                            __typename: 'ContentfulFormInput',
                            contentful_id: '7zmSW1RxABSaUYmQy5PgVb',
                            id: '6d4f8a87-1a34-5fda-818b-093ff52eb14e',
                            inputType: 'tel',
                            inputName: 'tel',
                            inputRequired: ['Check if required'],
                            inputLabel: 'Phone Number',
                            inputValue: null,
                            note: null,
                            placeholderText: null,
                            submitButtonColor: null,
                            submitButtonWidth: null,
                            usePasswordStrengthMeter: null,
                            useTwoColumnLayout: null,
                        },
                    ],
                },
            ],
        },
    ];

    try {
        const visibleInputs = JSON.parse(
            sessionStorage.getItem('ssoOriginFormInputs')
        ).reduce((acc, next) => {
            if (
                next?.inputType === 'text' ||
                next?.inputType === 'email' ||
                next?.inputType === 'password' ||
                next?.inputType === 'Radio' ||
                next?.inputType === 'Checkbox' ||
                next?.textareaName ||
                next?.selectOptions ||
                (next?.conditionalType && next?.conditionalKey)
            ) {
                acc = acc.concat(next);
            }

            return acc;
        }, []);

        if (!visibleInputs?.length) {
            return addConditionalComponentProps(defaults);
        }

        return addConditionalComponentProps(visibleInputs);
    } catch (error) {
        return addConditionalComponentProps(defaults);
    }
};

export const buildRecaptureFormInputs = (inputsToMap = []) => {
    try {
        const originalInputs = getRecaptureFormInputs();

        return inputsToMap.reduce((acc, next) => {
            if (
                (next?.inputName &&
                    originalInputs.some(
                        (x) => x?.inputName === next?.inputName
                    )) ||
                (next?.inputName === 'givenName' &&
                    !originalInputs.some(
                        (x) => x?.inputName === 'givenName'
                    )) ||
                (next?.inputName === 'familyName' &&
                    !originalInputs.some(
                        (x) => x?.inputName === 'familyName'
                    )) ||
                (next?.textareaName &&
                    originalInputs.some(
                        (x) => x?.textareaName === next?.textareaName
                    )) ||
                (next?.inputRadioCheckboxName &&
                    originalInputs.some(
                        (x) =>
                            x?.inputRadioCheckboxName ===
                            next?.inputRadioCheckboxName
                    )) ||
                (next?.selectName &&
                    !acc.some((x) => x?.selectName === next?.selectName))
            ) {
                acc = acc.concat(next);
            } else if (
                next?.inputType === 'hidden' ||
                next?.inputType === 'submit'
            ) {
                acc = acc.concat(next);
            }

            return acc;
        }, []);
    } catch (error) {
        return inputsToMap;
    }
};

export const appendEHawkTalonJs = () => {
    const eHawkTalonSettings = document.createElement('script');
    eHawkTalonSettings.src = '/scripts/ehawk-settings.js';
    eHawkTalonSettings.setAttribute('data-cookieconsent', 'ignore');
    document.body.appendChild(eHawkTalonSettings);
    const eHawkTalonJs = document.createElement('script');
    eHawkTalonJs.setAttribute('data-cookieconsent', 'ignore');
    eHawkTalonJs.src = '/scripts/EHawkTalon-6.0.7.js';
    document.body.appendChild(eHawkTalonJs);
};

export const validateOnChange = ['password', 'radio', 'checkbox'];

export const errorMessageToDisplay = (
    fieldErrorMessage,
    validatedOnSubmit = false,
    inputType,
    focused = false
) => {
    if (validateOnChange?.includes(inputType) && focused) {
        return false;
    }

    if (fieldErrorMessage === 'This field is required.' && !validatedOnSubmit) {
        return false;
    }

    if (fieldErrorMessage) {
        return true;
    }

    return false;
};

export const shouldDisplayErrorState = (
    errorMessage,
    inputType,
    serverValidationError,
    value,
    focused
) => {
    if (focused && value && errorMessage === 'This field is required.') {
        return false;
    }

    if (
        inputType === 'email' &&
        serverValidationError?.match(
            /Looks like you already have an account with us/
        )
    ) {
        return true;
    }

    return Boolean(errorMessage);
};

export const getInputErrorFromServer = (
    apiRoute,
    inputName,
    value,
    onSuccessCb = noop,
    onErrorCb = noop
) => {
    const route = getAbsoluteUrl(apiRoute);

    const options = {
        body: JSON.stringify({
            [inputName]: value,
        }),
        method: 'post',
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
        },
    };

    return callFetch(route, options, onSuccessCb, onErrorCb);
};

export const sanitizeURL = (url) => {
    try {
        const { origin, pathname } = new URL(url);
        return `${origin}${pathname}`.substring(0, 255);
    } catch (err) {
        return '';
    }
};

export const urlParamToSalesForceKeyMapping = {
    cc: 'Campaign_Code__c',
    ic: 'IC_Code__c',
    utm_source: 'UTM_Source__c',
    utm_medium: 'UTM_Medium__c',
    utm_campaign: 'UTM_Campaign__c',
    utm_content: 'UTM_Content__c',
    utm_term: 'UTM_Term__c',
    offerCode: 'offerCode',
};

export const getUtmCookiesFallback = () => {
    try {
        const cookies = new Cookies();
        const params = {};

        for (const key in urlParamToSalesForceKeyMapping) {
            const cookieVal = cookies.get(key);

            if (cookieVal) {
                params[urlParamToSalesForceKeyMapping[key]] = cookieVal;
            }
        }

        return params;
    } catch (error) {
        return null;
    }
};

export const buildDcObject = (searchParams) => {
    const validParams = Object.keys(urlParamToSalesForceKeyMapping);
    const params = {};

    searchParams.forEach((value, key) => {
        if (validParams.includes(key) && urlParamToSalesForceKeyMapping[key]) {
            params[urlParamToSalesForceKeyMapping[key]] = trimString(
                value,
                255
            );
        }
    });

    return params;
};

export const retriveUtmCampaignDataFromStorage = () => {
    try {
        return localStorage.getItem('utmCampaignParams')
            ? JSON.parse(localStorage.getItem('utmCampaignParams'))
            : null;
    } catch (err) {
        return null;
    }
};

export const includeSalesForceQueryParams = (referrer) => {
    let params = {};

    try {
        if (typeof window !== 'undefined') {
            const { searchParams } = new URL(window.location.href);
            const localStorageCampaignData =
                retriveUtmCampaignDataFromStorage();

            if (
                localStorageCampaignData &&
                typeof localStorageCampaignData === 'object' &&
                Object.keys(localStorageCampaignData).length
            ) {
                return localStorageCampaignData;
            }

            if (searchParams && searchParams?.size) {
                const paramsFromUrl = buildDcObject(searchParams);

                if (Object.keys(paramsFromUrl).length) {
                    params = {
                        ...params,
                        ...paramsFromUrl,
                    };
                }
            } else if (
                (!searchParams || !searchParams?.size) &&
                referrer &&
                referrer?.match(/constantcontact\.com/)
            ) {
                /* prefer search params from the page itself over the referrer but get the params from the referrer if any -- SVE 12/12/24 */
                const { searchParams: referrerSearchParams } = new URL(
                    referrer
                );

                if (referrerSearchParams && referrerSearchParams?.size) {
                    const paramsFromReferrer =
                        buildDcObject(referrerSearchParams);

                    if (Object.keys(paramsFromReferrer).length) {
                        params = {
                            ...params,
                            ...paramsFromReferrer,
                        };
                    }
                }
            }
        }

        return params;
    } catch (error) {
        return params;
    }
};

export const formatGoToUrl = (goToUrl) => {
    try {
        if (goToUrl && goToUrl?.match(/search/gi)) {
            return goToUrl
                .replace(/\s+/gi, '%20')
                .replace(
                    /search\/(.*)/,
                    (_, m1) =>
                        `search/${m1
                            .replaceAll('+', '%20')
                            .replaceAll('-', '%20')}`
                );
        }

        return goToUrl && typeof goToUrl === 'string'
            ? goToUrl.replace(/\s+/gi, '%20')
            : goToUrl;
    } catch (error) {
        return goToUrl;
    }
};

export const localizeDestinationPageUrl = (
    destinationPageUrl,
    useLocalizedDestination,
    defaultLocation
) => {
    try {
        const pathPrefix = defaultLocation
            ? defaultLocation === 'gb'
                ? 'uk'
                : defaultLocation
            : '';
        if (
            useLocalizedDestination &&
            destinationPageUrl &&
            typeof destinationPageUrl === 'string' &&
            defaultLocation
        ) {
            if (
                !new URL(destinationPageUrl)?.pathname?.startsWith(
                    `/${pathPrefix}`
                )
            ) {
                return destinationPageUrl.replace(
                    'constantcontact.com',
                    `constantcontact.com/${pathPrefix}`
                );
            }
        }

        return destinationPageUrl;
    } catch (error) {
        return destinationPageUrl;
    }
};

export const payloadContainsSendProvisionLetters = (postData = {}) => {
    try {
        if (postData?.body) {
            const postedFields = JSON.parse(postData.body);

            if (
                postedFields?.sendProvisionLetters === 'false' ||
                postedFields?.sendProvisionLetters === false
            ) {
                return true;
            }

            return false;
        }

        return false;
    } catch (error) {
        return false;
    }
};

export const onVerifiyRecaptchaError = (err, options) => {
    rg4js('send', {
        err,
        tags: ['error-recaptcha', 'verification-failed'],
        customData: { options },
    });
};

export const onVerifyRecaptchaApiFailure = (err, options) => {
    rg4js('send', {
        error: 'Recaptcha API failure',
        tags: ['error-recaptcha', 'unexpected-error'],
        customData: { err, options },
    });
};

export const insertMultpleAccountsCheckbox = (formInputs = []) => {
    try {
        const insertionIndex = formInputs.findIndex(
            (x) => x?.inputRadioCheckboxName === 'gdprOptOutEu'
        );

        if (!insertionIndex || insertionIndex < 0) {
            return formInputs;
        }

        const multipleAccountsCheckboxData = {
            __typename: 'ContentfulFormInputRadioOrCheckbox',
            contentful_id: '2NUsargrUWzOCb8gN8PyW9',
            id: '834a4901-03d9-5fe1-9508-5a5f8791ea8c',
            inputHeadline: null,
            inputRadioCheckboxName: 'Trial_Form_Question_1',
            inputRequired: null,
            inputStyle: 'Vertical',
            inputType: 'Checkbox',
            abTest: false,
            formInputs: [
                {
                    contentful_id: '4hd5bqKwBjBE0M4i0V2szM',
                    id: 'a65634f9-20a2-53a0-aeac-2a42761d2e9d',
                    inputLabel: {
                        raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[{"type":"bold"}],"value":"I need multiple accounts:","nodeType":"text"},{"data":{},"marks":[],"value":"  for franchisors, businesses, and other organizations who need centralized marketing for multiple locations.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
                    },
                    inputValue: 'true',
                    inputDefault: null,
                    ifSelectedText: null,
                },
            ],
        };

        const newFormInputs = [
            ...formInputs.slice(0, insertionIndex),
            multipleAccountsCheckboxData,
            ...formInputs.slice(insertionIndex),
        ];

        return newFormInputs;
    } catch (err) {
        return formInputs;
    }
};

export const addToDCObject = (postParams, data) => {
    try {
        if (
            data &&
            typeof data === 'object' &&
            data !== null &&
            Object?.keys(data)?.length
        ) {
            postParams = postParams?.dcObject
                ? {
                      ...postParams,
                      dcObject: {
                          ...postParams.dcObject,
                          ...data,
                      },
                  }
                : {
                      ...postParams,
                      dcObject: {
                          ...data,
                      },
                  };

            return postParams;
        }

        return postParams;
    } catch (error) {
        return postParams;
    }
};

export const addInputToForm = (formInputs = [], input) => {
    try {
        const insertionIndex = formInputs.findIndex(
            (x) => x?.inputRadioCheckboxName === 'gdprOptOutEu'
        );

        if (!insertionIndex || insertionIndex < 0) {
            return formInputs;
        }

        const newFormInputs = [
            ...formInputs.slice(0, insertionIndex),
            input,
            ...formInputs.slice(insertionIndex),
        ];

        return newFormInputs;
    } catch (err) {
        return formInputs;
    }
};
