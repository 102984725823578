module.exports = {
    en_US_USD: {
        // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en-US', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en',
        languageName: 'English',
        countryCode: 'us',
        countryName: 'United States',
        currency: 'USD',
        currencyWithSymbol: 'USD ($)',
        pathPrefix: '',
    },
    en_AU_AUD: {
        // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en-AU', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en-au',
        languageName: 'English',
        countryCode: 'au',
        countryName: 'Australia',
        currency: 'AUD',
        currencyWithSymbol: 'AUD (A$)',
        pathPrefix: '/au',
    },
    en_CA_CAD: {
        // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en-CA', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en-ca',
        languageName: 'English',
        countryCode: 'ca',
        countryName: 'Canada',
        currency: 'CAD (CA$)',
        pathPrefix: '/ca',
    },
    en_NZ_NZD: {
        // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en-NZ', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en-nz',
        languageName: 'English',
        countryCode: 'nz',
        countryName: 'New Zealand',
        currency: 'NZD (NZ$)', // CTCT-specific requirement - set currency to USD
        pathPrefix: '/nz',
    },
    en_GB_GBP: {
        // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en-GB', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en-gb',
        languageName: 'English',
        countryCode: 'gb',
        countryName: 'United Kingdom',
        currency: 'GBP',
        pathPrefix: '/uk',
    },
    en: {
        // our locale key (for interoperability between ctctfos & landingPages)
        localeCode: 'en', // official (for alternate tag & future Contentful localization (as needed))
        languageCode: 'en',
        languageName: 'English',
        countryCode: '',
    },
};
