// Central location for all colors used on the site
/**
 * @returns {string} A string of CSS to be used in an Emotion css rule
 *
 * color0 : '#ffffff' - white //
 * color1 : '#000000' - black //
 * color2 : '#fcfcfd' - frost //
 * color3 : '#f7f9fd' - vodka //
 * color4 : '#edf1f8' - overcast //
 * color5 : '#dce2ec' - silver //
 * color6 : '#c9d0de' - chinchilla //
 * color7 : '#8d9bb6' - steel //
 * color8 : '#7584a3' - dolphin //
 * color9 : '#63708a' - shark //
 * color10 : '#5d687e' - slate //
 * color11 : '#3f4b63' - pewter //
 * color12 : '#313b4d' - phantom //
 * color13 : '#0f141c' - shadow //
 * color14 : '#f7fcff' - powder //
 * color15 : '#edf9ff' - glacier //
 * color16 : '#d9f2ff' - arctic //
 * color17 : '#a6daff' - sky //
 * color18 : '#84dfff' - poolside //
 * color19 : '#4c9dff' - cornflower //
 * color20 : '#3b74ff' - byzantine //
 * color21 : '#2563fa' - azure //
 * color22 : '#1856ed' - ctctblue //
 * color23 : '#1739e5' - cobalt //
 * color24 : '#1330bf' - blueberry //
 * color25 : '#12277d' - sapphire //
 * color26 : '#05214b' - midnight //
 * color27 : '#fffbfa' - pearl //
 * color28 : '#fef3f2' - piglet //
 * color29 : '#fee4e2' - rose //
 * color30 : '#fecdca' - blush //
 * color31 : '#fda29b' - salmon //
 * color32 : '#f97066' - coral //
 * color33 : '#f04438' - chili //
 * color34 : '#d92d20' - cherry //
 * color35 : '#b42318' - brick //
 * color36 : '#912018' - crimson //
 * color37 : '#7a271a' - scarlet //
 * color38 : '#fffbf2' - chiffon //
 * color39 : '#fff5e5' - peach //
 * color40 : '#ffebcc' - cantaloupe //
 * color41 : '#ffcf99' - rajah //
 * color42 : '#ffbe73' - apricot //
 * color43 : '#ffb159' - tangerine //
 * color44 : '#ff9e1a' - ctctorange //
 * color45 : '#e97420' - pumpkin //
 * color46 : '#d6480b' - carrot //
 * color47 : '#b83e09' - salsa //
 * color48 : '#a63611' - amber //
 * color49 : '#f6fef9' - dew //
 * color50 : '#ecfdf3' - mint //
 * color51 : '#dbfae6' - spearmint //
 * color52 : '#b6f8b6' - pistachio //
 * color53 : '#a9efc5' - seafoam //
 * color54 : '#75e0a7' - gin //
 * color55 : '#47cd89' - mantis //
 * color56 : '#17b26a' - girlscout //
 * color57 : '#079455' - shamrock //
 * color58 : '#007646' - emerald //
 * color59 : '#085d3a' - pine //
 * color60 : '#074d31' - forrest //
 * color61 : '#fbf7ff' - orchid //
 * color62 : '#f8f2ff' - petal //
 * color63 : '#f0e5ff' - lavender //
 * color64 : '#e3ceff' - periwinkle //
 * color65 : '#ba91f2' - iris //
 * color66 : '#a273e5' - lilac //
 * color67 : '#8e5bc9' - plum //
 * color68 : '#6f3eb2' - grape //
 * color69 : '#532a81' - violet //
 * color70 : '#3c1f66' - mystic //
 * color71 : '#2d174c' - blackberry //
 */

export const ctctColors = {
    // black text
    color0: '#ffffff', // white
    color2: '#fcfcfd', // frost
    color3: '#f7f9fd', // vodka
    color4: '#edf1f8', // overcast
    color5: '#dce2ec', // silver
    color6: '#c9d0de', // chinchilla
    color7: '#8d9bb6', // steel
    color8: '#7584a3', // dolphin
    color14: '#f7fcff', // powder
    color15: '#edf9ff', // glacier
    color16: '#d9f2ff', // arctic
    color17: '#a6daff', // sky
    color18: '#84dfff', // poolside
    color19: '#4c9dff', // cornflower
    color20: '#3b74ff', // byzantine
    color27: '#fffbfa', // pearl
    color28: '#fef3f2', // piglet
    color29: '#fee4e2', // rose
    color30: '#fecdca', // blush
    color31: '#fda29b', // salmon
    color32: '#f97066', // coral
    color33: '#f04438', // chili
    color38: '#fffbf2', // chiffon
    color39: '#fff5e5', // peach
    color40: '#ffebcc', // cantaloupe
    color41: '#ffcf99', // rajah
    color42: '#ffbe73', // apricot
    color43: '#ffb159', // tangerine
    color44: '#ff9e1a', // ctctorange
    color45: '#e97420', // pumpkin
    color49: '#f6fef9', // dew
    color50: '#ecfdf3', // mint
    color51: '#dbfae6', // spearmint
    color52: '#b6f8b6', // pistachio
    color53: '#a9efc5', // seafoam
    color54: '#75e0a7', // gin
    color55: '#47cd89', // mantis
    color56: '#17b26a', // girlscout
    color57: '#079455', // shamrock
    color61: '#fbf7ff', // orchid
    color62: '#f8f2ff', // petal
    color63: '#f0e5ff', // lavender
    color64: '#e3ceff', // periwinkle
    color65: '#ba91f2', // iris
    color66: '#a273e5', // lilac

    // white text
    color1: '#000000', // black
    color9: '#63708a', // shark
    color10: '#5d687e', // slate
    color11: '#3f4b63', // pewter
    color12: '#313b4d', // phantom
    color13: '#0f141c', // shadow
    color21: '#2563fa', // azure
    color22: '#1856ed', // ctctblue
    color23: '#1739e5', // cobalt
    color24: '#1330bf', // blueberry
    color25: '#12277d', // sapphire
    color26: '#05214b', // midnight
    color34: '#d92d20', // cherry
    color35: '#b42318', // brick
    color36: '#912018', // crimson
    color37: '#7a271a', // scarlet
    color46: '#d6480b', // carrot
    color47: '#b83e09', // salsa
    color48: '#a63611', // amber
    color58: '#007646', // emerald
    color59: '#085d3a', // pine
    color60: '#074d31', // forrest
    color67: '#8e5bc9', // plum
    color68: '#6f3eb2', // grape
    color69: '#532a81', // violet
    color70: '#3c1f66', // mystic
    color71: '#2d174c', // blackberry
};

export const ctctReadableColors = {
    white: ctctColors.color0,
    black: ctctColors.color1,
    frost: ctctColors.color2,
    vodka: ctctColors.color3,
    overcast: ctctColors.color4,
    silver: ctctColors.color5,
    chinchilla: ctctColors.color6,
    steel: ctctColors.color7,
    dolphin: ctctColors.color8,
    shark: ctctColors.color9,
    slate: ctctColors.color10,
    pewter: ctctColors.color11,
    phantom: ctctColors.color12,
    shadow: ctctColors.color13,
    powder: ctctColors.color14,
    glacier: ctctColors.color15,
    arctic: ctctColors.color16,
    sky: ctctColors.color17,
    poolside: ctctColors.color18,
    cornflower: ctctColors.color19,
    byzantine: ctctColors.color20,
    azure: ctctColors.color21,
    ctctblue: ctctColors.color22,
    cobalt: ctctColors.color23,
    blueberry: ctctColors.color24,
    sapphire: ctctColors.color25,
    midnight: ctctColors.color26,
    pearl: ctctColors.color27,
    piglet: ctctColors.color28,
    rose: ctctColors.color29,
    blush: ctctColors.color30,
    salmon: ctctColors.color31,
    coral: ctctColors.color32,
    chili: ctctColors.color33,
    cherry: ctctColors.color34,
    brick: ctctColors.color35,
    crimson: ctctColors.color36,
    scarlet: ctctColors.color37,
    chiffon: ctctColors.color38,
    peach: ctctColors.color39,
    cantaloupe: ctctColors.color40,
    rajah: ctctColors.color41,
    apricot: ctctColors.color42,
    tangerine: ctctColors.color43,
    ctctorange: ctctColors.color44,
    pumpkin: ctctColors.color45,
    carrot: ctctColors.color46,
    salsa: ctctColors.color47,
    amber: ctctColors.color48,
    dew: ctctColors.color49,
    mint: ctctColors.color50,
    spearmint: ctctColors.color51,
    pistachio: ctctColors.color52,
    seafoam: ctctColors.color53,
    gin: ctctColors.color54,
    mantis: ctctColors.color55,
    girlscout: ctctColors.color56,
    shamrock: ctctColors.color57,
    emerald: ctctColors.color58,
    pine: ctctColors.color59,
    forrest: ctctColors.color60,
    orchid: ctctColors.color61,
    petal: ctctColors.color62,
    lavender: ctctColors.color63,
    periwinkle: ctctColors.color64,
    iris: ctctColors.color65,
    lilac: ctctColors.color66,
    plum: ctctColors.color67,
    grape: ctctColors.color68,
    violet: ctctColors.color69,
    mystic: ctctColors.color70,
    blackberry: ctctColors.color71,
};

// Keep track of all background colors that should coincide with black text
export const blackTextColors = [
    ctctColors.color0, // white
    ctctColors.color2, // frost
    ctctColors.color3, // vodka
    ctctColors.color4, // overcast
    ctctColors.color5, // silver
    ctctColors.color6, // chinchilla
    ctctColors.color7, // steel
    ctctColors.color8, // dolphin
    ctctColors.color14, // powder
    ctctColors.color15, // glacier
    ctctColors.color16, // arctic
    ctctColors.color17, // sky
    ctctColors.color18, // poolside
    ctctColors.color19, // cornflower
    ctctColors.color20, // byzantine
    ctctColors.color27, // pearl
    ctctColors.color28, // piglet
    ctctColors.color29, // rose
    ctctColors.color30, // blush
    ctctColors.color31, // salmon
    ctctColors.color32, // coral
    ctctColors.color33, // chili
    ctctColors.color38, // chiffon
    ctctColors.color39, // peach
    ctctColors.color40, // cantaloupe
    ctctColors.color41, // rajah
    ctctColors.color42, // apricot
    ctctColors.color43, // tangerine
    ctctColors.color44, // ctctorange
    ctctColors.color45, // pumpkin
    ctctColors.color49, // dew
    ctctColors.color50, // mint
    ctctColors.color51, // spearmint
    ctctColors.color52, // pistachio
    ctctColors.color53, // seafoam
    ctctColors.color54, // gin
    ctctColors.color55, // mantis
    ctctColors.color56, // girlscout
    ctctColors.color57, // shamrock
    ctctColors.color61, // orchid
    ctctColors.color62, // petal
    ctctColors.color63, // lavender
    ctctColors.color64, // periwinkle
    ctctColors.color65, // iris
    ctctColors.color66, // lilac
];

// Keep track of all background colors that should coincide with white text
export const whiteTextColors = [
    ctctColors.color1, // black
    ctctColors.color9, // shark
    ctctColors.color10, // slate
    ctctColors.color11, // pewter
    ctctColors.color12, // phantom
    ctctColors.color13, // shadow
    ctctColors.color21, // azure
    ctctColors.color22, // ctctblue
    ctctColors.color23, // cobalt
    ctctColors.color24, // blueberry
    ctctColors.color25, // sapphire
    ctctColors.color26, // midnight
    ctctColors.color34, // cherry
    ctctColors.color35, // brick
    ctctColors.color36, // crimson
    ctctColors.color37, // scarlet
    ctctColors.color46, // carrot
    ctctColors.color47, // salsa
    ctctColors.color48, // amber
    ctctColors.color58, // emerald
    ctctColors.color59, // pine
    ctctColors.color60, // forrest
    ctctColors.color67, // plum
    ctctColors.color68, // grape
    ctctColors.color69, // violet
    ctctColors.color70, // mystic
    ctctColors.color71, // blackberry
];
