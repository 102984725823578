import Cookies from 'universal-cookie';
import Scroll from 'react-scroll';
import { debounce, isEqual, upperFirst } from 'lodash';
import queryString from 'query-string';
import { generateImageData } from 'gatsby-plugin-image';
import locales from '../../../config/locales';
import localePathPrefixes from '../../../config/locale-path-prefixes';
import formToCookieKeys from '../../../config/form-to-cookie-keys';
import { locations } from './locations-data';
import urlToCookieKeys from '../../../config/url-to-cookie-keys';
import goToWwwUrlKeys from '../../../config/go-to-www-url-keys';
import countryCodesToCurrencies from '../../../config/country-codes-to-currencies';
import countrySupportNumbers from '../../../config/country-support-numbers';
import { ctctColors, whiteTextColors } from '../../../config/colors';
import { callFetch } from './helpers-form';
import {
    domainRegex,
    formatUrl,
    getRecaptureFormInputs,
    buildDcObject,
} from '../../../components/form/utils';
import memoizeFn from './memoizeFn';

/* memoize functions with complex args */
const comparators = [isEqual];

const rg4js = typeof window !== 'undefined' ? require('raygun4js') : null;

// JS URLs
export const cookiebotJsUrl = 'https://consent.cookiebot.com/uc.js';
export const transcendJsTestUrl =
    'https://transcend-cdn.com/cm-test/ceb33a79-6942-496d-82eb-e6af9c7658ef/airgap.js';
export const transcendJsUrl =
    'https://transcend-cdn.com/cm/ceb33a79-6942-496d-82eb-e6af9c7658ef/airgap.js';

// cookie names
export const googleAnalyticsClientIdForCtctCookieName = '_ga_ctct';
export const optimizelyFullStackEndUserIdCookieName =
    'optimizelyFullStackEndUserId';
export const optimizelyFullStackABExperimentKeyForConditionalComponentsCookieName =
    'optimizely-full-stack-ab-experiment-key-for-conditional-components';
export const optimizelyFullStackABVariationKeyForConditionalComponentsCookieName =
    'optimizely-full-stack-ab-variation-key-for-conditional-components';
export const optimizelyFullStackExperimentsHistoryCookieName =
    'optimizely-full-stack-experiments-history';

// IDs
export const googleAnalyticsTrackingId = 'UA-138462344-1';
export const cookiebotId = '36973317-3a13-4d05-864e-97fcc940b9ee';

// EU + a few exceptions (UK + EFTA countries (which primarily do business w/ the EU))
export const gdprCountries = [
    'at', // Austria
    'be', // Belgium
    'bg', // Bulgaria
    'hr', // Croatia
    'cy', // Cyprus
    'cz', // Czech Republic (or Czechia)
    'dk', // Denmark
    'ee', // Estonia
    'fi', // Finland
    'fr', // France
    'de', // Germany
    'gr', // Greece
    'hu', // Hungary
    'ie', // Ireland
    'it', // Italy
    'lv', // Latvia
    'lt', // Lithuania
    'lu', // Luxembourg
    'mt', // Malta
    'nl', // Netherlands
    'pl', // Poland
    'pt', // Portugal
    'ro', // Romania
    'sk', // Slovakia
    'si', // Slovenia
    'es', // Spain
    'se', // Sweden
    'gb', // United Kingdom (not in EU, but still subject to GDPR regulations)
    'no', // Norway (not in EU, but still subject to GDPR regulations via EFTA)
    'is', // Iceland (not in EU, but still subject to GDPR regulations via EFTA)
    'li', // Liechtenstein (not in EU, but still subject to GDPR regulations via EFTA)
    'ch', // Switzerland (not in EU, but still subject to GDPR regulations via EFTA)
];

export const getHostEnv = (hostname) => {
    let hostEnv = '';

    if (hostname.includes('.d1.')) {
        hostEnv = 'd1.';
    } else if (hostname.includes('.l1.')) {
        hostEnv = 'l1.';
    } else if (hostname.includes('.s1.')) {
        hostEnv = 's1.';
    }

    return hostEnv;
};

export const getTealiumEnv = (hostname) => {
    let tealiumEnv = 'prod';

    if (hostname.includes('.d1.')) {
        tealiumEnv = 'dev';
    } else if (hostname.includes('.s1.') || hostname.includes('.l1.')) {
        tealiumEnv = 'qa';
    }

    return tealiumEnv;
};

export const getGeolocation = () => {
    const cookies = new Cookies();
    const geolocation = cookies.get('ctct-geolocated-country-code') || 'us';

    return geolocation;
};

export const searchParamsDedupedObj = (urlSearchParamsObj) => {
    const searchParamsDedupedObj = {};

    if (urlSearchParamsObj) {
        for (const p of urlSearchParamsObj) {
            const key = p[0];
            const value = p[1];

            // skip duplicate keys (the convention (like Java's request.getParam()) is to get the first instance of a query param key)
            if (!searchParamsDedupedObj.hasOwnProperty(key)) {
                searchParamsDedupedObj[key] = value;
            }
        }
    }

    return searchParamsDedupedObj;
};

export const buildMergedRedirectDestinationSearchParams = (
    sourceUrlSearchParamsObj,
    destinationUrlSearchParamsObj
) => {
    const sourceSearchParamsDedupedObj = searchParamsDedupedObj(
        sourceUrlSearchParamsObj
    );
    const destinationSearchParamsDedupedObj = searchParamsDedupedObj(
        destinationUrlSearchParamsObj
    );
    const mergedRedirectDestinationSearchParamsObj = {};
    let mergedRedirectDestinationSearchParamsObjSorted = {};

    // add sourceSearchParamsObj (if it's not null or empty) to mergedRedirectDestinationSearchParamsObj
    if (
        sourceSearchParamsDedupedObj &&
        Object.keys(sourceSearchParamsDedupedObj).length > 0
    ) {
        for (const key in sourceSearchParamsDedupedObj) {
            mergedRedirectDestinationSearchParamsObj[key] =
                sourceSearchParamsDedupedObj[key];
        }
    }

    // add destinationSearchParamsObj (if it's not null or empty) to mergedRedirectDestinationSearchParamsObj
    if (
        destinationSearchParamsDedupedObj &&
        Object.keys(destinationSearchParamsDedupedObj).length > 0
    ) {
        /**
         * If mergedRedirectDestinationSearchParamsObj already contains the same key, that key's value will be overwritten from
         * destinationSearchParams (b/c that value will be the final one in the redirect destination URL).
         */
        for (const key in destinationSearchParamsDedupedObj) {
            mergedRedirectDestinationSearchParamsObj[key] =
                destinationSearchParamsDedupedObj[key];
        }
    }

    // sort mergedRedirectDestinationSearchParamsObj key-value pairs by key in alphabetical order
    if (
        mergedRedirectDestinationSearchParamsObj &&
        Object.keys(mergedRedirectDestinationSearchParamsObj).length > 0
    ) {
        mergedRedirectDestinationSearchParamsObjSorted = Object.keys(
            mergedRedirectDestinationSearchParamsObj
        )
            .sort()
            .reduce((mergedRedirectDestinationSearchParamsObjSorted, key) => {
                mergedRedirectDestinationSearchParamsObjSorted[key] =
                    mergedRedirectDestinationSearchParamsObj[key];
                return mergedRedirectDestinationSearchParamsObjSorted;
            }, {});
    }

    // build mergedRedirectDestinationSearchParams string
    let mergedRedirectDestinationSearchParams = '';

    if (
        mergedRedirectDestinationSearchParamsObjSorted &&
        Object.keys(mergedRedirectDestinationSearchParamsObjSorted).length > 0
    ) {
        mergedRedirectDestinationSearchParams = '';

        for (const key in mergedRedirectDestinationSearchParamsObjSorted) {
            mergedRedirectDestinationSearchParams = `${mergedRedirectDestinationSearchParams}&${key}=${mergedRedirectDestinationSearchParamsObjSorted[key]}`;
        }
    }

    // if mergedRedirectDestinationSearchParams string is currently at least "&[x]" (where "x" is the first query string key)
    if (mergedRedirectDestinationSearchParams.length >= 2) {
        mergedRedirectDestinationSearchParams = `?${mergedRedirectDestinationSearchParams.substring(
            1
        )}`; // becomes "?[key1]=[value1]&..."
    } else {
        mergedRedirectDestinationSearchParams = '';
    }

    return mergedRedirectDestinationSearchParams;
};

export const KebabCase = (string) => {
    if (string && typeof string === 'string') {
        return string
            .match(
                /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
            )
            .map((x) => x.toLowerCase())
            .join('-');
    }

    return '';
};

export const PascalCase = (str) => {
    return str
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
            index === 0 ? match.toUpperCase() : match.toUpperCase()
        )
        .replace(/\s+/g, '');
};

export const CamelCase = (str) => {
    return str
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
            index === 0 ? match.toLowerCase() : match.toUpperCase()
        )
        .replace(/\s+/g, '');
};

export const StateToState = (string) => {
    for (let i = 0; locations.length; i++) {
        if (locations[i].regionCode.toLowerCase() === string) {
            return locations[i].regionDisplay;
        }
    }
};

export function normalizeData(props) {
    if (!props || typeof props !== 'object' || !Object?.keys(props)?.length) {
        return;
    }

    Object.keys(props).map((prop) => {
        if (props[prop] === undefined) {
            return false;
        }

        const property = props[prop];

        // Hot reloaded props are coming in undefined on save.
        // This prevents the hot reloading from getting borked.
        if (property['en-US'] === undefined) {
            return false;
        }

        // If the props value is an array iterate through each index.
        // If the index value has a "sys" property, first normalize the data
        // and then assign the index value to the prop
        if (Array.isArray(property['en-US'])) {
            const subProps = property['en-US'].map((item) => {
                if (item.hasOwnProperty('sys')) {
                    const { fields, sys } = item;

                    normalizeData(fields);

                    item = fields;
                    item.fields = fields;
                    item.sys = sys;

                    return item;
                }

                return false;
            });

            return (props[prop] = subProps);
        }

        // If the prop is not an array but has a "sys" property, first normalize
        // the data and then assign the value to the prop
        if (property['en-US'].hasOwnProperty('fields')) {
            const { fields } = property['en-US'];

            normalizeData(fields);

            return (props[prop] = fields);
        }
        // Strip the locale from the prop
        return (props[prop] = property['en-US']);
    });
}

function generateImageSource(
    filename,
    width,
    height,
    toFormat,
    _fit, // We use resizingBehavior instead
    imageTransformOptions
) {
    const imageFormatDefaults = imageTransformOptions[`${toFormat}Options`];
    const CONTENTFUL_IMAGE_MAX_SIZE = 4000;
    const validImageFormats = new Set([`jpg`, `png`, `webp`, `gif`, `avif`]);
    function createUrl(imgUrl, options = {}) {
        // If radius is -1, we need to pass `max` to the API
        const cornerRadius =
            options.cornerRadius === -1 ? `max` : options.cornerRadius;

        if (options.toFormat === `auto`) {
            delete options.toFormat;
        }

        // Convert to Contentful names and filter out undefined/null values.
        const urlArgs = {
            w: options.width || undefined,
            h: options.height || undefined,
            fl:
                options.toFormat === `jpg` && options.jpegProgressive
                    ? `progressive`
                    : undefined,
            q: options.quality || undefined,
            fm: options.toFormat || undefined,
            fit: options.resizingBehavior || undefined,
            f: options.cropFocus || undefined,
            bg: options.background || undefined,
            r: cornerRadius || undefined,
        };

        const isBrowser = typeof window !== `undefined`;

        const searchParams = isBrowser
            ? new window.URLSearchParams()
            : new URLSearchParams();

        for (const paramKey in urlArgs) {
            if (typeof urlArgs[paramKey] !== `undefined`) {
                searchParams.append(paramKey, urlArgs[paramKey] ?? ``);
            }
        }

        return `https:${imgUrl}?${searchParams.toString()}`;
    }

    if (
        imageFormatDefaults &&
        Object.keys(imageFormatDefaults).length !== 0 &&
        imageFormatDefaults.constructor === Object
    ) {
        imageTransformOptions = {
            ...imageTransformOptions,
            ...imageFormatDefaults,
        };
    }

    const {
        jpegProgressive,
        quality,
        cropFocus,
        backgroundColor,
        resizingBehavior,
        cornerRadius,
    } = imageTransformOptions;
    // Ensure we stay within Contentfuls Image API limits
    if (width > CONTENTFUL_IMAGE_MAX_SIZE) {
        height = Math.floor((height / width) * CONTENTFUL_IMAGE_MAX_SIZE);
        width = CONTENTFUL_IMAGE_MAX_SIZE;
    }

    if (height > CONTENTFUL_IMAGE_MAX_SIZE) {
        width = Math.floor((width / height) * CONTENTFUL_IMAGE_MAX_SIZE);
        height = CONTENTFUL_IMAGE_MAX_SIZE;
    }

    if (!validImageFormats.has(toFormat)) {
        console.warn(
            `[gatsby-source-contentful] Invalid image format "${toFormat}". Supported types are jpg, png, webp and avif"`
        );
        return undefined;
    }

    const src = createUrl(filename, {
        width,
        height,
        toFormat,
        resizingBehavior,
        background: backgroundColor?.replace(`#`, `rgb:`),
        quality,
        jpegProgressive,
        cropFocus,
        cornerRadius,
    });
    return { width, height, format: toFormat, src };
}

function serializer(replacer, cycleReplacer) {
    const stack = [];
    const keys = [];

    if (cycleReplacer == null)
        cycleReplacer = (key, value) => {
            if (stack[0] === value) return '[Circular ~]';
            return `[Circular ~.${keys
                .slice(0, stack.indexOf(value))
                .join('.')}]`;
        };

    return (key, value) => {
        if (stack.length > 0) {
            const thisPos = stack.indexOf(this);
            ~thisPos ? stack.splice(thisPos + 1) : stack.push(this);
            ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key);
            if (~stack.indexOf(value))
                value = cycleReplacer.call(this, key, value);
        } else stack.push(value);

        return replacer == null ? value : replacer.call(this, key, value);
    };
}

function stringify(obj, replacer, spaces, cycleReplacer) {
    return JSON.stringify(obj, serializer(replacer, cycleReplacer), spaces);
}

export function formatContentfullyData(
    props,
    richTextQueries,
    onSetQueryError
) {
    Object.keys(props).map((prop) => {
        const property = props[prop];

        // Hide the preview pages just in case
        if (prop === 'metaRobots') {
            props[prop] = 'noindex, nofollow';
        }

        if (property === 'html') {
            props.__typename = 'ContentfulHtml';
            props[property] = { html: props[property] };
        }

        if (property && property.hasOwnProperty('_type')) {
            // Add the __typename to align with gatsby query
            const typename = property._type;
            property.__typename = `Contentful${
                typename.charAt(0).toUpperCase() + typename.slice(1)
            }`;

            if (property._type === 'image' && property.url !== undefined) {
                property.file = { url: property.url };
            }
        }

        if (prop === 'imageOrFullImageCard') {
            if (property?.desktopImage) {
                property.desktopImage.parent = 'Hero';
            }

            if (property?.tabletImage) {
                property.tabletImage.parent = 'Hero';
            }

            if (property?.mobileImage) {
                property.mobileImage.parent = 'Hero';
            }
        }

        if (prop === 'imageOrVideo' && props?._type) {
            if (property?.desktopImage) {
                property.desktopImage.parent = props?._type;
            }

            if (property?.tabletImage) {
                property.tabletImage.parent = props?._type;
            }

            if (property?.mobileImage) {
                property.mobileImage.parent = props?._type;
            }
        }

        if (property?.contentType) {
            property.mimeType = property.contentType;
            if (
                property?.contentType?.match(/image/) &&
                !property.dimensions &&
                props?.dimensions
            ) {
                property.dimensions = props.dimensions;
            }

            if (
                property?.contentType?.match(/image/) &&
                property.dimensions &&
                !property?.contentType?.match(/svg/)
            ) {
                try {
                    const resolveGatsbyImageData = (image, options) => {
                        const filename = image.src;

                        const sourceMetadata = {
                            width: image.width,
                            height: image.height,
                            format: image.mimeType.split('/')[1],
                        };

                        const imageDataArgs = {
                            ...options,
                            pluginName: `gatsby-source-example`,
                            sourceMetadata,
                            filename,
                            placeholderURL: filename,
                            generateImageSource,
                            options,
                        };

                        return generateImageData(imageDataArgs);
                    };

                    const getImageDimensions = () => {
                        if (
                            (prop === 'desktopImage' ||
                                prop === 'tabletImage') &&
                            property?.parent &&
                            property?.parent === 'Hero' &&
                            property.dimensions.width < 999
                        ) {
                            return {
                                width: 999,
                                height: Math.max(
                                    (999 / property.dimensions.width) *
                                        property.dimensions.height
                                ),
                            };
                        }

                        if (
                            prop === 'mobileImage' &&
                            property?.parent &&
                            property?.parent === 'Hero' &&
                            property.dimensions.width < 767
                        ) {
                            return {
                                width: 767,
                                height: Math.max(
                                    (767 / property.dimensions.width) *
                                        property.dimensions.height
                                ),
                            };
                        }

                        if (
                            prop === 'desktopImage' &&
                            property?.parent &&
                            property?.parent === 'fullImageWithContent' &&
                            property.dimensions.width < 2400
                        ) {
                            return {
                                width: 2400,
                                height: Math.max(
                                    (2400 / property.dimensions.width) *
                                        property.dimensions.height
                                ),
                            };
                        }

                        if (
                            prop === 'tabletImage' &&
                            property?.parent &&
                            property?.parent === 'fullImageWithContent' &&
                            property.dimensions.width < 1200
                        ) {
                            return {
                                width: 1200,
                                height: Math.max(
                                    (1200 / property.dimensions.width) *
                                        property.dimensions.height
                                ),
                            };
                        }

                        if (
                            prop === 'mobileImage' &&
                            property?.parent &&
                            property?.parent === 'fullImageWithContent' &&
                            property.dimensions.width < 600
                        ) {
                            return {
                                width: 600,
                                height: Math.max(
                                    (600 / property.dimensions.width) *
                                        property.dimensions.height
                                ),
                            };
                        }

                        if (
                            prop === 'image' &&
                            props?._type &&
                            props?._type === 'merchTile' &&
                            property?.dimensions?.width &&
                            property?.dimensions?.height
                        ) {
                            return {
                                width: 210,
                                height: Math.max(
                                    (210 / property.dimensions.width) *
                                        property.dimensions.height
                                ),
                            };
                        }

                        return {
                            width: property.dimensions.width,
                            height: property.dimensions.height,
                        };
                    };

                    const gatsbyImageData = resolveGatsbyImageData(
                        {
                            ...getImageDimensions(),
                            src: property.url,
                            mimeType: property.contentType,
                        },
                        {}
                    );
                    property.gatsbyImageData = gatsbyImageData;
                } catch (error) {
                    property.gatsbyImageData = null;
                }
            }
        }

        if (property?.content && Array.isArray(property.content)) {
            const getReferences = (reference) =>
                reference.reduce((acc, next) => {
                    if (next?.data?._type || next?._type) {
                        const item = {};
                        const target = next?.data ? next?.data : next;
                        for (const x in target) {
                            if (Array.isArray(target[x])) {
                                item[x] = getReferences(target[x]);
                            } else {
                                item[x] = target[x];
                            }
                        }

                        acc = acc.concat({
                            ...item,
                            __typename: `Contentful${upperFirst(item._type)}`,
                            contentful_id: item?.contentful_id || item?._id,
                        });
                    }
                    return acc;
                }, []);

            const references = getReferences(property.content);

            const getSysLinks = (links, checkLinks = false) =>
                links?.reduce((acc, next) => {
                    const getNextData = (obj) => {
                        if (obj?.target?.fields) {
                            return {
                                obj,
                                ...obj.target.fields,
                            };
                        }

                        return obj;
                    };

                    if (!Object?.keys(next?.data)?.length) {
                        acc = acc.concat({
                            ...next,
                            content:
                                next.content && Array.isArray(next.content)
                                    ? getSysLinks(next.content, true)
                                    : next.content,
                        });
                    } else {
                        if (
                            checkLinks &&
                            next?.nodeType === 'entry-hyperlink' &&
                            next?.data &&
                            next?.content?.length === 1
                        ) {
                            next.content[0].data = {
                                target: {
                                    sys: {
                                        id: next?.data?._id,
                                        linkType: 'Entry',
                                        type: 'Link',
                                    },
                                },
                            };
                        }

                        acc = acc.concat({
                            content: next.content,
                            nodeType: next.nodeType,
                            data:
                                next?.nodeType === 'hyperlink' ||
                                (next?.nodeType === 'entry-hyperlink' &&
                                    !checkLinks) ||
                                (next?.nodeType === 'embedded-entry-inline' &&
                                    !checkLinks)
                                    ? getNextData(next?.data)
                                    : {
                                          target: {
                                              sys: {
                                                  id: next?.data?._id,
                                                  linkType: 'Entry',
                                                  type: 'Link',
                                              },
                                          },
                                      },
                            rawData: next.data,
                        });
                    }
                    return acc;
                }, []);

            const textBlockData = getSysLinks(property.content);

            const linkRefs = textBlockData.reduce((acc, next) => {
                if (next?.content?.length) {
                    const refs = next.content.reduce((a, n) => {
                        if (
                            n?.nodeType === 'entry-hyperlink' &&
                            n?.data?.target?.sys?.id &&
                            n?.rawData
                        ) {
                            a = a.concat({
                                ...n.rawData,
                                __typename: `Contentful${upperFirst(
                                    n.rawData._type
                                )}`,
                                contentful_id:
                                    n.rawData?.contentful_id || n.rawData?._id,
                            });
                        }

                        if (
                            n?.nodeType === 'embedded-entry-inline' &&
                            n?.data?.target?.sys?.id &&
                            n?.rawData
                        ) {
                            a = a.concat({
                                ...n.rawData,
                                __typename: `Contentful${upperFirst(
                                    n.rawData._type
                                )}`,
                                contentful_id:
                                    n.rawData?.contentful_id || n.rawData?._id,
                            });
                        }

                        return a;
                    }, []);

                    if (refs?.length) {
                        acc = acc.concat(refs);
                    }
                }

                return acc;
            }, []);

            const raw = stringify({
                content: textBlockData,
                data: {},
                nodeType: 'document',
            });

            property.raw = raw;
            property.references = [...linkRefs, ...references].reduce(
                (acc, next) => {
                    if (next?.target?.fields) {
                        acc = acc.concat({
                            ...next,
                            ...next.target.fields,
                        });
                    } else {
                        acc = acc.concat(next);
                    }
                    return acc;
                },
                []
            );

            try {
                if (richTextQueries) {
                    if (
                        Object.keys(richTextQueries).includes(prop) &&
                        property?.references?.length
                    ) {
                        const queryError = property.references.reduce(
                            (acc, next) => {
                                if (
                                    !richTextQueries?.[prop]?.includes(
                                        next?.__typename
                                    ) &&
                                    !acc.components.includes(next?.__typename)
                                ) {
                                    acc.components = acc.components.concat(
                                        next?.__typename
                                    );
                                    acc.str = acc.str.concat(
                                        `${next?.__typename} query may be missing\n`
                                    );
                                }
                                return acc;
                            },
                            { str: '', components: [] }
                        );

                        if (queryError?.str) {
                            onSetQueryError(queryError.str);
                        }
                    }
                }
            } catch (err) {
                if (err) {
                    console.warn(err);
                }
            }
        }

        if (prop === 'data') {
            const { url } = property;
            const { target } = property;
            const { title } = property;
            const { linkText } = property;

            if (property.linkText && !property.target.fields) {
                property.target = {
                    fields: {
                        linkText,
                        url,
                        title,
                        target,
                    },
                    sys: {
                        contentful_id: '',
                        id: '',
                    },
                };

                if (property.hasOwnProperty('modal')) {
                    property.target.fields.modal = property.modal;
                    property.target.fields.title = property.title;
                }
            }
        }

        if (Array.isArray(property)) {
            property.map((item, i) => {
                if (
                    item.hasOwnProperty('content') &&
                    item._type === 'tinyMceRichTextBlock'
                ) {
                    if (property[i].content.internal === undefined) {
                        const tinyContent = property[i].content;
                        property[i].content = {
                            internal: { content: tinyContent },
                        };
                    }
                }

                switch (item._type) {
                    case 'heroNew':
                        item._type = 'Hero';
                        break;
                    case 'mediaBlocks':
                        item._type = 'MediaBlockWrapper';
                        break;
                    case 'textBlock':
                        item._type = 'OneColumnTextBlock';
                        break;
                    case 'tabs':
                        item._type = 'TabsWrapperGeneric';
                        break;
                    default:
                }

                formatContentfullyData(item, richTextQueries, onSetQueryError);

                return false;
            });
        }

        if (
            property &&
            (property.hasOwnProperty('_id') ||
                property.hasOwnProperty('subContent') ||
                property.hasOwnProperty('content'))
        ) {
            if (property.hasOwnProperty('url')) {
                property.file = property?.contentType
                    ? {
                          url: property.url,
                          contentType: property.contentType,
                          mimeType: property.contentType,
                      }
                    : { url: property.url };
            }

            formatContentfullyData(property, richTextQueries, onSetQueryError);
        }

        return false;
    });
}

export const getRelativeUrl = (url) => {
    const cookies = new Cookies();
    const ctctSOPSCookieVal = cookies.get('sops');

    if (url) {
        const urlObj = new URL(url, 'https://www.constantcontact.com'); // using prod origin as the base (only for the purposes of creating a URL object -- not actually using this base)
        if (urlObj) {
            const { pathname } = urlObj;
            const { search } = urlObj;

            // SOPS home page destination href, if applicable
            const processHomePageHrefsForSOPSDestinationHref =
                processHomePageHrefsForSOPS(
                    pathname,
                    search,
                    ctctSOPSCookieVal
                );
            if (processHomePageHrefsForSOPSDestinationHref) {
                return processHomePageHrefsForSOPSDestinationHref;
            }
        }
    }

    return url;
};

export const isValidHttpUrl = (string) => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.hostname;
};

export const getURLComponents = (address) => {
    try {
        return new URL(address);
    } catch (err) {
        return {
            hostname: address,
            searchParams: new URLSearchParams(),
            err,
        };
    }
};

export const getAbsoluteUrl = (url) => {
    /*
        This is where the environment variable is picked up from the
        package.json build scripts. Example: npm run build:l1 makes
        env = 'l1'.

        NOTE: This can NOT be destructured as the read only property
        is only available during run/build time.
    */

    const sandBox = /.(d1|l1|s1)./;

    let env; // assume in prod

    if (typeof window !== 'undefined') {
        if (sandBox.test(window.location.hostname)) {
            env = window.location.hostname.match(sandBox)[1];

            if (env !== undefined) {
                if (
                    url ===
                    'https://login.constantcontact.online/landing/no-auth?goTo=new-site'
                ) {
                    if (env === 's1') {
                        url =
                            'https://login.uat.ctctdev.co/landing/no-auth?goTo=new-site';
                    } else {
                        url =
                            'https://login.qa.ctctdev.co/landing/no-auth?goTo=new-site';
                    }
                }

                if (
                    url ===
                    'https://login.constantcontact.online/landing/no-auth?goTo=new-store'
                ) {
                    if (env === 's1') {
                        url =
                            'https://login.uat.ctctdev.co/landing/no-auth?goTo=new-store';
                    } else {
                        url =
                            'https://login.qa.ctctdev.co/landing/no-auth?goTo=new-store';
                    }
                }

                if (
                    url ===
                    'https://login.constantcontact.online/landing/no-auth?goTo=new-logo'
                ) {
                    if (env === 's1') {
                        url =
                            'https://login.uat.ctctdev.co/landing/no-auth?goTo=new-logo';
                    } else {
                        url =
                            'https://login.qa.ctctdev.co/landing/no-auth?goTo=new-logo';
                    }
                }
            }
        }
    }

    const urlHost = isValidHttpUrl(url);
    if (
        url &&
        urlHost &&
        (urlHost.includes('go.constantcontact') ||
            urlHost.includes('www.constantcontact') ||
            urlHost.includes('app.constantcontact')) &&
        env !== undefined
    ) {
        const urlObj = new URL(url);

        if (urlObj) {
            const { protocol, hostname, pathname } = urlObj;
            let { search } = urlObj;
            const searchObj = queryString?.parse(search, '?');
            const originalUrl = searchObj?.OriginalURL;

            // check originalUrl - hostname does not contain l1 or s1 && is whitelisted
            if (originalUrl) {
                /* defend against addresses that are not fully qualified URLs */
                const originalUrlObj = isValidHttpUrl(originalUrl)
                    ? new URL(originalUrl)
                    : getURLComponents(`${protocol}//${originalUrl}`);

                let originalUrlHostname = originalUrlObj?.hostname;

                if (
                    !originalUrlHostname.includes('.l1.') &&
                    !originalUrlHostname.includes('.s1.') &&
                    originalUrlHostname.match(
                        '.*[.]?(constantcontact.com|constantcontact.online|ctctdev.co|prodcc.net|retentionsandbox.com|retentionscience.com|roving.com)$'
                    )
                ) {
                    // break down hostname into 2 parts and insert environment
                    originalUrlHostname = `${
                        originalUrlHostname.split('.')[0]
                    }.${env}.${originalUrlHostname.split('.')[1]}.${
                        originalUrlHostname.split('.')[2]
                    }`;
                }

                searchObj.OriginalURL = `${originalUrlObj.protocol}//${originalUrlHostname}${originalUrlObj.pathname}${originalUrlObj.search}`;
            }

            search = queryString.stringify(searchObj);

            if (
                hostname.includes('www.constantcontact') &&
                typeof window !== 'undefined'
            ) {
                url = `${protocol}//${
                    window.location.hostname // for testing via local, PR, and integration www hostnames
                }${pathname}`;
            } else {
                url = `${protocol}//${hostname.split('.')[0]}.${env}.${
                    hostname.split('.')[1]
                }.${hostname.split('.')[2]}${pathname}`;
            }

            if (search) {
                url = `${url}?${search}`;
            }
        }
    }

    return url;
};

export const getAbsoluteUrlBuild = (url) => {
    let env =
        process.env.GATSBY_ENV !== undefined
            ? process.env.GATSBY_ENV
            : undefined;

    if (
        process.env.gatsby_executing_command === 'develop' ||
        (process.env.BRANCH_NAME &&
            process.env.BRANCH_NAME.indexOf('PR-') !== -1) ||
        process.env.GATSBY_ENV === 'l1'
    ) {
        env = 'l1';
    } else if (
        process.env.BRANCH_NAME === 'staging' ||
        process.env.GATSBY_ENV === 's1'
    ) {
        env = 's1';
    }

    const urlHost = isValidHttpUrl(url);
    if (
        url &&
        urlHost &&
        (urlHost.includes('go.constantcontact') ||
            urlHost.includes('www.constantcontact') ||
            urlHost.includes('app.constantcontact')) &&
        env !== undefined
    ) {
        const urlObj = new URL(url);

        if (urlObj) {
            const { protocol, hostname, pathname } = urlObj;
            let { search } = urlObj;
            const searchObj = queryString?.parse(search, '?');
            const originalUrl = searchObj?.OriginalURL;

            // check originalUrl - hostname does not contain l1 or s1 && is whitelisted
            if (originalUrl) {
                /* defend against addresses that are not fully qualified URLs */
                const originalUrlObj = isValidHttpUrl(originalUrl)
                    ? new URL(originalUrl)
                    : getURLComponents(`${protocol}//${originalUrl}`);

                let originalUrlHostname = originalUrlObj?.hostname;

                if (
                    !originalUrlHostname.includes('.l1.') &&
                    !originalUrlHostname.includes('.s1.') &&
                    originalUrlHostname.match(
                        '.*[.]?(constantcontact.com|constantcontact.online|ctctdev.co|prodcc.net|retentionsandbox.com|retentionscience.com|roving.com)$'
                    )
                ) {
                    // break down hostname into 2 parts and insert environment
                    originalUrlHostname = `${
                        originalUrlHostname.split('.')[0]
                    }.${env}.${originalUrlHostname.split('.')[1]}.${
                        originalUrlHostname.split('.')[2]
                    }`;
                }

                searchObj.OriginalURL = `${originalUrlObj.protocol}//${originalUrlHostname}${originalUrlObj.pathname}${originalUrlObj.search}`;
            }

            search = queryString.stringify(searchObj);

            if (
                hostname.includes('www.constantcontact') &&
                typeof window !== 'undefined'
            ) {
                url = `${protocol}//${
                    window.location.hostname // for testing via local, PR, and integration www hostnames
                }${pathname}`;
            } else {
                url = `${protocol}//${hostname.split('.')[0]}.${env}.${
                    hostname.split('.')[1]
                }.${hostname.split('.')[2]}${pathname}`;
            }

            if (search) {
                url = `${url}?${search}`;
            }
        }
    }

    return url;
};

export const moveToAnchorId = (url) => {
    // check for links with ids in them
    const id = url.split('#')[1];

    // if the id in the url of the button clicked
    // matches an id on the current page, scroll
    // to the corresponding element
    if (document.getElementById(id) !== null) {
        Scroll.scroller.scrollTo(id, {
            duration: 1500,
            smooth: true,
        });
    }
};

export const getOmUrl = (url) => {
    return url
        ? url?.includes('.com/')
            ? ` /${url?.split('.com/')[1]}`
            : ` ${url}`
        : '';
};

// adapted from https://stackoverflow.com/questions/23013573/swap-key-with-value-in-object
export const swapObjectKeysAndValues = (obj) => {
    const swapped = {};
    for (const key in obj) {
        swapped[obj[key]] = key;
    }

    return swapped;
};

export const getQueryParamVal = (queryParams, queryParamName) => {
    const queryParamList = new URLSearchParams(queryParams);
    const returnVal = '';
    // we have to map through query param values becuase some params will have
    // different casings, so we can't use queryParamList.get()
    for (const [key, value] of queryParamList) {
        if (
            // if the key is the same as the supplied queryParamName, or if the key
            // matches one of our translated keys, return the value
            key.toLowerCase() === queryParamName?.toLowerCase() ||
            goToWwwUrlKeys[key.toLowerCase()] === queryParamName
        ) {
            return value;
        }
    }
    return returnVal;
};

export const itemizeSufCookieValues = (cookieValue, formatAsObj) => {
    const dataStructure = formatAsObj ? {} : []; // make it more versatile so we're not writing duplicate functions

    decodeURIComponent(cookieValue)
        .split('|')
        .forEach((value) => {
            const valuePair = value.split('=');
            if (dataStructure.isArray) {
                dataStructure.push(valuePair);
            } else {
                dataStructure[valuePair[0]] = valuePair[1];
            }
        });
    return dataStructure;
};

export const itemizeQueryParams = (queryParams) => {
    const paramsArray = [];
    const params = queryParams.substring(1);

    params.split('&').forEach((value) => {
        paramsArray.push(value.split('='));
    });
    return paramsArray;
};

export const hashQueryParams = (queryParams) => {
    const paramsArray = {};
    const params = queryParams.substring(1);

    params.split('&').forEach((value) => {
        paramsArray[value.split('=')[0]] = value.split('=')[1];
    });
    return paramsArray;
};

export const transposeQueryParamToSufCookieVal = (queryParams) => {
    const cookieVal = [];
    const params = itemizeQueryParams(queryParams); // split up the query params into an array of arrays

    params.forEach((param) => {
        if (param[1]) {
            let key = param[0];
            const val = param[1];
            key =
                typeof urlToCookieKeys[key] === 'undefined'
                    ? goToWwwUrlKeys[key]
                    : key; // if the key map doesn't exist, check if it's a key from go
            typeof key !== 'undefined' &&
                cookieVal.push(`${urlToCookieKeys[key]}=${val}`); // if the key is ultimately valid, push a new cookie value
        }
    });
    return cookieVal && decodeURIComponent(cookieVal.join('|'));
};

export const mapQueryParamsAndSufValues = (
    queryParamsOrCookieValues,
    formatAsObj
) => {
    const mappedDataStructure = formatAsObj ? {} : [];
    const arrayToMap = itemizeQueryParams(queryParamsOrCookieValues);
    arrayToMap.map((value) => {
        let key = value[0];
        const val = value[1];
        key =
            typeof urlToCookieKeys[key] === 'undefined'
                ? goToWwwUrlKeys[key]
                : key; // if the key map doesn't exist, check if it's a key from go
        if (mappedDataStructure.isArray) {
            mappedDataStructure.push([urlToCookieKeys[key], val]);
        } else {
            mappedDataStructure[urlToCookieKeys[key]] = val;
        }
    });
    return mappedDataStructure;
};

export const getUpdatedSufCookieValue = (cookieValString, queryParams) => {
    const returnValue = itemizeSufCookieValues(cookieValString, true); // get all the cookie values as an object of arrays
    const params = mapQueryParamsAndSufValues(queryParams, true); // translate key names to proper cookie keys

    Object.entries(params).forEach(([key, val]) => {
        // loop through and update any existing properties, and add non-existing ones
        returnValue[key] = val;
    });
    return (
        returnValue &&
        decodeURIComponent(
            Object.entries(returnValue)
                .map((value) => value.join('='))
                .join('|')
        )
    );
};

export const updateCookieValueByInput = (cookieValString, input) => {
    const { name, value } = input;
    const spChars = /[#$%^*_+\-?!"=\[\]{};\\|<>\/]+/; //eslint-disable-line

    if (!value || name === 'newPassword') {
        // bail if no value or is password field
        return false;
    }

    if (name === 'url') {
        if (
            !value ||
            (value &&
                formatUrl(value) &&
                !formatUrl(value)?.toLowerCase()?.match(domainRegex))
        ) {
            return false;
        }
    } else if (name === 'tel') {
        if (!value || !/^[0-9-]*$/.test(value)) {
            return false;
        }
    } else if (spChars.test(value)) {
        return false;
    }

    const returnValue =
        typeof cookieValString !== 'undefined'
            ? itemizeSufCookieValues(cookieValString, true)
            : {};

    // Update the cookie object using pii mapping
    returnValue[formToCookieKeys[name]] = value;

    const mapping = {
        firstName: 'givenName',
        lastName: 'familyName',
    };
    const helperKey = mapping[name] || name;
    const formHelpersStr = sessionStorage.getItem('formHelpers');
    const formHelpersObj = formHelpersStr ? JSON.parse(formHelpersStr) : {};
    formHelpersObj[helperKey] = value;
    sessionStorage.setItem('formHelpers', JSON.stringify(formHelpersObj));

    return (
        returnValue &&
        decodeURIComponent(
            Object.entries(returnValue)
                .map((value) => value.join('='))
                .join('|')
        )
    );
};

export const getSufCookieVal = (cookieValString, fieldToMatch) => {
    let returnValue;
    const reversedMappedParamNames = swapObjectKeysAndValues(formToCookieKeys); // we need to reverse the query params to read the cookie values
    const cookieVals = decodeURIComponent(cookieValString).split('|');
    cookieVals &&
        cookieVals.forEach((val) => {
            if (reversedMappedParamNames[val.split('=')[0]] === fieldToMatch) {
                returnValue = val.split('=')[1];
            }
        });
    return returnValue;
};

export const getSingleSufCookieVal = (cookieValString, valToMatch) => {
    let returnValue;
    const cookieVals = decodeURIComponent(cookieValString).split('|');
    if (cookieVals) {
        cookieVals.forEach((val) => {
            if (val?.split('=')?.[0] === valToMatch) {
                returnValue = val?.split('=')?.[1];
            }
        });
    }
    return returnValue;
};

export const getCookieDomain = () => {
    const preProdEnvRegex = /.(d1|l1|s1)./;
    let preProdEnv = '';
    if (typeof window !== 'undefined') {
        if (preProdEnvRegex.test(window.location.hostname)) {
            preProdEnv = window.location.hostname.match(preProdEnvRegex)[1];
        }
    }

    let cookieDomain = '';
    if (typeof window !== 'undefined') {
        if (window.location.hostname.endsWith('.constantcontact.com')) {
            if (preProdEnv) {
                cookieDomain = window.location.hostname.substring(
                    window.location.hostname.indexOf(`.${preProdEnv}`)
                );
            } else {
                cookieDomain = '.constantcontact.com'; // prod
            }
        }
    }

    return cookieDomain;
};

/**
 * @param {string} cssClass - a string containing a hex color or a kebab cased CSS class
 * @param {boolean} returnBackgroundClassOnly - a boolean to determine if only the background class should be returned
 * @param {Array} classOverrides - an array of two CSS classes to override the default white and black text classes.
 * The first index should be the dark backround class, and the second index should be the light background class
 *
 * @returns {string} - a kebab cased CSS class
 */
export const handleColorClass = (
    cssClass,
    returnBackgroundClassOnly = false,
    classOverrides = null
) => {
    const classesToReturn = classOverrides || [' white-text', ' black-text'];
    // if the cssClass is a hex color, return the assigned CSS class
    if (cssClass?.length && cssClass?.startsWith('#')) {
        let colorClass =
            swapObjectKeysAndValues(ctctColors)?.[cssClass?.toLowerCase()];
        const backgroundColorClass = whiteTextColors.includes(
            ctctColors[colorClass]
        )
            ? classesToReturn[0]
            : classesToReturn[1];
        // check if the color should have black or white text
        colorClass += backgroundColorClass;

        // trim whitespace from backgroundColorClass
        return returnBackgroundClassOnly
            ? backgroundColorClass.trim()
            : colorClass;
    }

    // otherwise just return the fallback kebab cased CSS class
    return KebabCase(cssClass);
};

export const addCustomClasses = (classBase, customClasses) => {
    // return all custom classes with base class prepended
    return customClasses
        ?.map((customClass) =>
            customClass ? `${classBase}${customClass}` : null
        )
        ?.join(' ')
        .trim();
};

// Stolen from user956584 in this SO post: https://stackoverflow.com/a/68398236
//  Modified to accept 0 opacity values
export const addOpacityToHexCode = (color, opacity) => {
    // coerce values so it is between 0 and 1.
    const _opacity = opacity
        ? Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
        : '00';
    return color + _opacity.toString(16).toUpperCase();
};

/**
 *
 * @param {Object} styleOptions - a JS object containing all the metadata needed to construct a string of CSS rules
 * @returns string
 * @structure – styleOptions = {
        propName: [
            true, // something that evaluates to truthy/falsey/null/undefined
            'cssProp', // CSS property
            'cssValue', // CSS value
        ],
    }
 *
 */
export const buildStyleString = (
    styleOptions = {
        propName: [
            true, // something that evaluates to truthy/falsey/null/undefined
            'cssProp', // CSS property
            'cssValue', // CSS value
        ],
    }
) => {
    const styles = Object.entries(styleOptions)
        .map((rule) => {
            return `${rule[1][1]}: ${rule[1][2]};`;
        })
        .join('');
    return styles;
};

export const isProdEnv = () => {
    const preProdEnvRegex = /.(d1|l1|s1)./;
    let isProd = true;

    if (typeof window !== 'undefined') {
        isProd = !preProdEnvRegex.test(window.location.hostname);
    }

    return isProd;
};

/** Localization - START - for our SEO component's "alternate" tags */
export const getLocalePathPrefix = (pathname) => {
    let localePathPrefix = '';

    if (pathname) {
        const tokens = pathname.split('/');
        let localePathPrefixCode = '';

        if (tokens.length >= 2) {
            localePathPrefixCode = tokens[1];
        }

        localePathPrefix = `/${localePathPrefixCode}`;

        if (!localePathPrefixes.includes(localePathPrefix)) {
            localePathPrefix = ''; // default to en_US_USD (no locale path prefix) if localePathPrefixCode is nonexistent OR not valid
        }
    }

    return localePathPrefix;
};

export const stripLocalePathPrefix = (pathname) => {
    const localePathPrefix = getLocalePathPrefix(pathname);

    if (localePathPrefix) {
        if (pathname === localePathPrefix) {
            pathname = pathname.replace(localePathPrefix, ''); // .replace(...), w/o the '/g' modifier, only replaces the first match, which is what we want
        } else {
            pathname = pathname.replace(`${localePathPrefix}/`, '/'); // .replace(...), w/o the '/g' modifier, only replaces the first match, which is what we want
        }
    }

    if (pathname === '') {
        pathname = '/'; // special case - after a non-US root pathname like /au has its locale path prefix stripped, pathnameLowercasedWithoutLocalePathPrefix = ''
    }

    return pathname;
};

export const getPathnameBasedOnLocale = (pathname, locale) => {
    const localeCountryCode = locales[locale].countryCode;

    let newPathname = '';

    const pathnameWithoutLocalePathPrefix = stripLocalePathPrefix(pathname);

    switch (localeCountryCode) {
        case 'us': // default -- the only locale that we're supporting now
        default:
            if (pathnameWithoutLocalePathPrefix) {
                newPathname = pathnameWithoutLocalePathPrefix;
            }

            break;
    }

    return newPathname;
};

export const getCountryCodeByCurrency = (currencyCode) => {
    for (const [countryCode, details] of Object.entries(
        countryCodesToCurrencies
    )) {
        if (details.currency === currencyCode) {
            return countryCode;
        }
    }
    return null;
};

export const getCountrySupportNumber = (countryCode) => {
    return (
        countrySupportNumbers[getCountryCodeByCurrency(countryCode)] ||
        countrySupportNumbers.us
    );
};

/** Localization - END - for our SEO component's "alternate" tags */

export const processHomePageHrefsForSOPS = (
    pathname,
    queryParams,
    ctctSOPSCookieVal
) => {
    let newPathname = '';
    let newHref = '';

    if (pathname === '/') {
        if (ctctSOPSCookieVal) {
            if (ctctSOPSCookieVal.includes('T')) {
                newPathname = '/trial-home';
            } else if (ctctSOPSCookieVal.includes('O')) {
                newPathname = '/account-home';
            }
        }
    }

    if (newPathname) {
        newHref = newPathname + queryParams;
    }

    return newHref;
};

// normalize partner values to be lowercased (except for "ROVING" & "NATSEARCH", special cases that need to be ALL CAPS)
export const normalizePartner = (partner) => {
    let partnerNormalized = '';

    if (partner) {
        partnerNormalized = partner.toLowerCase();

        if (
            partnerNormalized === 'roving' ||
            partnerNormalized === 'natsearch'
        ) {
            // special cases that need to be ALL CAPS
            partnerNormalized = partner.toUpperCase();
        }
    } else {
        partnerNormalized = 'ROVING'; // default partner is "ROVING" (ALL CAPS)
    }

    return partnerNormalized;
};

export const parseHeadline = (headline) => {
    const brs = headline.split('<br>');

    return brs
        .map((br) => {
            // find anything that's bold
            const boldText = br.match(/(\*.*)/g);

            if (boldText && boldText.length > 0) {
                return boldText.map((text) => {
                    const textArr = text.split('*');
                    textArr[0] = '<b>';
                    textArr[2] = '</b>';
                    return textArr.join('');
                });
            }

            return br;
        })
        .join('\n');
};

// load JS file (includes removing old script before appending new one)
export const loadScript = (
    url,
    async = true,
    attributes = {},
    callback = null,
    invokeOnLoadEvent = false
) => {
    if (typeof url !== 'string' || !url.trim()) {
        throw new Error('Invalid URL provided');
    }

    if (typeof async !== 'boolean') {
        throw new Error('Async parameter must be a boolean');
    }

    if (typeof attributes !== 'object') {
        throw new Error('Attributes parameter must be an object');
    }

    if (callback !== null && typeof callback !== 'function') {
        throw new Error('Callback parameter must be a function or null');
    }

    if (!document || !document.head) {
        throw new Error('Cannot find document or head element');
    }

    let script;
    try {
        script = document.createElement('script');
    } catch (error) {
        throw new Error('Error creating script element');
    }

    // remove old scripts before appending new one
    const existingScripts = document.querySelectorAll(`script[src="${url}"]`);
    existingScripts.forEach((s) => s.remove());

    if (url) {
        script.src = url;
    }

    if (async) {
        script.async = true;
    }

    for (const [key, value] of Object.entries(attributes)) {
        script.setAttribute(key, value);
    }

    try {
        if (invokeOnLoadEvent && callback && typeof callback === 'function') {
            /* ^^ load event listener not working when called from layout.jsx file -- SVE 12/17/24 */
            script.onload = () => {
                callback();
            };
        } else {
            script.addEventListener('load', () => {
                if (callback) {
                    callback();
                }
            });
        }
        document.head.appendChild(script);
    } catch (error) {
        throw new Error('Error appending script element');
    }
};

// unload JS - via filename only (for instance, if we're targeting https://cdn.cookielaw.org/scripttemplates/6.36.0/otBannerSdk.js (loaded indirectly by OneTrust) w/ a version # that could change)
export const unloadScriptFilenameOnly = (filename) => {
    const scriptTag = document.querySelector(`script[src*="${filename}"]`);

    // remove old script (via filename only)
    if (scriptTag) {
        scriptTag.parentElement.removeChild(scriptTag);
    }
};

// CTCTFOS team - custom functionality
export const setOptimizelyWebTestLaneCookie = () => {
    const cookies = new Cookies();

    if (!cookies.get('test_lane')) {
        cookies.set(
            'test_lane',
            getRandomNumberForOptimizelyWebTestLaneCookie(1, 100).toString(10),
            {
                domain: getCookieDomain(),
                maxAge: 315360000,
                path: '/',
                sameSite: 'lax',
            }
        ); // Max-Age = 315360000s = 10 years (user should remain in same test lane for 10 years)
    }
};

export const getRandomNumberForOptimizelyWebTestLaneCookie = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/** Google Analytics */
export const getGoogleAnalyticsAccountInfoForOptimizelyFullStackRedirects = (
    redirectSourceUrl,
    referrerUrl,
    experimentKey,
    experimentId,
    variationKey,
    variationId
) => {
    return {
        dl: redirectSourceUrl, // document location URL = redirectSourceUrl from a Link component
        ds: 'web', // data source
        dr: referrerUrl, // document referrer = URL of page that contains redirectSourceUrl within a Link component
        ec: 'optimizely-full-stack', // event category
        ea: `${experimentKey}-${experimentId}`, // event action
        el: `${variationKey}-${variationId}`, // event label
        ni: 1, // a non-interaction hit
        t: 'event', // hit type
        tid: googleAnalyticsTrackingId, // tracking ID
        v: '1', // Google Analytics - Measurement Protocol version
    };
};

export const transmitGoogleAnalyticsPayload = (payload, userAgent) => {
    const body = queryString.stringify(payload);

    const fetchPromise = fetch('https://www.google-analytics.com/collect', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'User-Agent': userAgent,
        },
        body,
    });

    fetchPromise.then(() => {
        const sentAnalyticsForOptimizelyFullStackRedirectEvent =
            new CustomEvent(
                'sentAnalyticsForOptimizelyFullStackRedirectEvent',
                { detail: { sentAnalyticsToGoogleAnalyticsForRedirect: true } }
            );
        window.dispatchEvent(sentAnalyticsForOptimizelyFullStackRedirectEvent);
    });
};

/** for CTCTFOS - Optimizely Full Stack */
export const obtainOptimizelyFullStackEndUserId = () => {
    const cookies = new Cookies();

    let optimizelyFullStackEndUserId;

    optimizelyFullStackEndUserId = cookies.get(
        optimizelyFullStackEndUserIdCookieName
    );
    if (!optimizelyFullStackEndUserId) {
        optimizelyFullStackEndUserId = `oeu${Date.now()}r${Math.random()}`;
        cookies.set(
            optimizelyFullStackEndUserIdCookieName,
            optimizelyFullStackEndUserId,
            {
                domain: getCookieDomain(),
                maxAge: 15552000,
                path: '/',
                sameSite: 'lax',
            }
        ); // Max-Age = 15552000s = 180 days
    }

    return optimizelyFullStackEndUserId;
};

export const isStaging = (incomingHostname) =>
    incomingHostname.indexOf('.s1.') !== -1 ||
    incomingHostname.indexOf('l1') !== -1 ||
    incomingHostname.indexOf('localhost') !== -1;
export const isQA = (incomingHostname) =>
    incomingHostname.indexOf('.l1.') !== -1;

export const obtainOptimizelyFullStackDatafileUrl = (incomingHostname) => {
    let datafileKey = '';
    let datafileUrl = '';
    if (isQA(incomingHostname)) {
        datafileKey = 'L6Pw34QGEDQt9tYt8GfCq';
    } else if (isStaging(incomingHostname)) {
        datafileKey = 'L6Pw34QGEDQt9tYt8GfCq';
    } else {
        datafileKey = 'WTUaAWb9mjeUrtARofZJW';
    }

    datafileUrl = `https://cdn.optimizely.com/datafiles/${datafileKey}.json`;

    return datafileUrl;
};

export const dispatchOptimizelyFullStackEvent = (event) => {
    const fetchPromise = fetch(event.url, {
        body: JSON.stringify(event.params),
        headers: {
            'content-type': 'application/json',
        },
        method: event.httpVerb,
    });

    const ruleType =
        event.params.visitors[0].snapshots[0].decisions[0].metadata.rule_type;

    fetchPromise.then((response) => {
        if (ruleType === 'feature-test') {
            const sentAnalyticsForOptimizelyFullStackRedirectEvent =
                new CustomEvent(
                    'sentAnalyticsForOptimizelyFullStackRedirectEvent',
                    {
                        detail: {
                            sentAnalyticsToOptimizelyFullStackForRedirect: true,
                        },
                    }
                );
            window.dispatchEvent(
                sentAnalyticsForOptimizelyFullStackRedirectEvent
            );
        }
    });

    return fetchPromise;
};

export const checkForForm = () => {
    const pageForms = document.forms;
    if (!pageForms === undefined || pageForms.length >= 1) {
        // forms exist, lets check the ids
        for (let i = 0; i < pageForms.length; i++) {
            const form = pageForms[i];
            const formId = form.id ? form.id : 'form';

            if (formId !== window.formLoaded) {
                if (typeof window !== 'undefined' && window.utag) {
                    window.formLoaded = formId; // store title of loaded form so we do not send duplicate events when form if changed on page

                    window.utag.link({
                        tealium_event: 'impression',
                        ga_event_category: 'impression',
                        ga_event_action: `Form>${formId}`,
                        ga_event_label: `${window.location.pathname}>Form>${formId}`,
                        ga_event_link_name: formId,
                        ga_nonInteraction: true,
                    });
                }
            }
        }
    }

    return true;
};

export const handlePostSignupProcessingPage = (destinationUrl) => {
    const hostEnv = getHostEnv(window.location.hostname);
    let tealiumDataCollectionCompleted = false;

    const cookies = new Cookies();
    // sso iframe flow check
    const inProvisionFlowSsoIframe = cookies.get('inProvisionFlowSsoIframe');

    sessionStorage.removeItem('formHelpers');

    if (
        sessionStorage.getItem('inProvisionFlowIframe') &&
        !inProvisionFlowSsoIframe
    ) {
        sessionStorage.removeItem('inProvisionFlowIframe');
        return;
    }

    if (inProvisionFlowSsoIframe === 'true') {
        // no redirect since this is already the destinationUrl
        sessionStorage.removeItem('inProvisionFlow');
        sessionStorage.removeItem('inProvisionFlowDestinationUrl');

        sessionStorage.removeItem('inProvisionFlowIframe');
        cookies.remove('inProvisionFlowSsoIframe');
    }

    const onAfterAnalyticsSuccess = () => {
        sessionStorage.removeItem('inProvisionFlow');
        sessionStorage.removeItem('SSOProvisionIFrameSet');

        if (inProvisionFlowSsoIframe !== 'true') {
            /* 
                    we need these items for later retrieval 
                    in a different flow where they will
                    be removed manually (see layout.jsx around line 192)
                    -- SVE 8/21/2023
                */
            sessionStorage.removeItem('storedFormId');
            sessionStorage.removeItem('SSOSuccessInIframe');
        }

        destinationUrl =
            destinationUrl ||
            `https://app.${hostEnv}constantcontact.com/pages/dashboard/home/#/?utm_source=provision-login`;
        window.location.replace(destinationUrl);
    };

    document.addEventListener('tealiumDataCollectionCompleted', () => {
        tealiumDataCollectionCompleted = true;
        onAfterAnalyticsSuccess();
    });

    // fallback
    setTimeout(() => {
        if (!tealiumDataCollectionCompleted) {
            onAfterAnalyticsSuccess();
        }
    }, 4000);
};

export const handleOnClickCookiebotLinks = () => {
    if (typeof window !== 'undefined') {
        try {
            if (!window?.CookieConsent) {
                loadScript(cookiebotJsUrl, false, {
                    id: 'Cookiebot',
                    'data-cbid': cookiebotId,
                    'data-blockingmode': 'auto',
                    'data-georegions':
                        "{'region':'US-06','cbid':'0b732793-8382-4d70-9050-c95355d4f3a5'}",
                });
                window.addEventListener(
                    'CookiebotOnConsentReady',
                    () => {
                        window.CookieConsent.renew();
                    },
                    { once: true }
                );
            } else {
                window.CookieConsent.renew();
            }
        } catch (error) {
            if (window?.console) {
                console.warn(error);
            }
        }
    }
};

// Cookiebot cookie consent
export const toggleCookiebotCookieSettingsModal = () =>
    handleOnClickCookiebotLinks();

// The polling function
export const poll = (fn, timeout, interval) => {
    const endTime = Number(new Date()) + (timeout || 2000);
    interval = interval || 100;

    var checkCondition = function (resolve, reject) {
        // If the condition is met, we're done!
        const result = fn();
        if (result) {
            resolve(result);
        }
        // If the condition isn't met but the timeout hasn't elapsed, go again
        else if (Number(new Date()) < endTime) {
            setTimeout(checkCondition, interval, resolve, reject);
        }
        // Didn't match and too much time, reject!
        else {
            reject(new Error(`timed out for ${fn}: ${arguments}`));
        }
    };

    return new Promise(checkCondition);
};

export const formatPhoneNumber = (phoneNumber) => {
    // takes a phone number string and returns a new string formatted as '###-###-####':
    const phoneNumberPattern = /^(\d{3})(\d{3})(\d{4})$/;
    const matches = phoneNumberPattern.exec(phoneNumber);
    if (matches) {
        return `${matches[1]}-${matches[2]}-${matches[3]}`;
    }
    return phoneNumber;
};

export const isImageSvg = (imageSrc = '') => {
    if (imageSrc && typeof imageSrc === 'string') {
        return imageSrc?.endsWith('.svg');
    }

    return false;
};

export const createProvisionSuccessIframe = debounce(
    (formId, isSSOSuccess = false) => {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = isSSOSuccess
            ? `${window.location.origin}/post-signup/processing?formId=${formId}&method=sso`
            : `${window.location.origin}/post-signup/processing?formId=${formId}`;
        document.body.appendChild(iframe);
    },
    300
);

export const getVisibleInputs = (inputs = []) => {
    try {
        return inputs.reduce((a, n) => {
            if (
                n.inputName &&
                n.inputType !== 'hidden' &&
                n.inputType !== 'submit'
            ) {
                a = a.concat(n.inputName);
            }
            return a;
        }, []);
    } catch (err) {
        return inputs;
    }
};

export const handleSSOPostSignup = (
    souid,
    destinationUrl,
    flowType,
    socialProvider
) => {
    const cookieDomain = getCookieDomain();
    const hostEnv = getHostEnv(window.location.hostname);
    const signupApiPostSignupProcessingEndpoint = `https://go.${hostEnv}constantcontact.com/api/signup-api/post-signup/processing?soid=${souid}`;

    const onSuccess = (response, opts, httpStatus) => {
        const cookies = new Cookies();

        // sso iframe flow check
        const inProvisionFlowSsoIframe = cookies.get(
            'inProvisionFlowSsoIframe'
        );

        if (
            sessionStorage.getItem('inProvisionFlowIframe') &&
            !inProvisionFlowSsoIframe
        ) {
            sessionStorage.removeItem('inProvisionFlowIframe');
            return;
        }

        if (inProvisionFlowSsoIframe === 'true') {
            // no redirect since this is already the destinationUrl
            sessionStorage.removeItem('inProvisionFlow');
            sessionStorage.removeItem('inProvisionFlowDestinationUrl');

            sessionStorage.removeItem('inProvisionFlowIframe');
            cookies.remove('inProvisionFlowSsoIframe');
        }

        sessionStorage.removeItem('inProvisionFlow');
        sessionStorage.removeItem('SSOProvisionIFrameSet');

        if (inProvisionFlowSsoIframe !== 'true' && flowType !== 'try') {
            const storedFormId = sessionStorage.getItem('storedFormId');
            sessionStorage.setItem('SSOProvisionIFrameSet', 'true');
            createProvisionSuccessIframe(storedFormId, true);
            sessionStorage.removeItem('storedFormId');
            sessionStorage.removeItem('SSOSuccessInIframe');
        }

        let destination =
            destinationUrl ||
            `https://app.${hostEnv}constantcontact.com/pages/dashboard/home/#/?utm_source=provision-login`;

        if (flowType === 'try') {
            const destinationUrlParts = new URL(destinationUrl);
            const { host, protocol, pathname, search } = destinationUrlParts;
            const goto = getQueryParamVal(search, 'goto');

            const originalSignupFormInputs = getVisibleInputs(
                getRecaptureFormInputs()
            );

            if (originalSignupFormInputs?.length > 2) {
                destination = `${protocol}//${host}/${pathname}?goto=${encodeURIComponent(
                    `${protocol}//${
                        window.location.host
                    }/account-setup/signup?gotourl=${encodeURIComponent(
                        goto
                    )}&ssoSuccess=true&flowType=try&socialProvider=${socialProvider}&ssoid=${souid}`
                )}`;
            }
        }

        window.location.replace(destination);
    };

    const onFailure = (error, opts) => {
        if (rg4js) {
            rg4js('send', {
                error,
                tags: ['sso_error'],
            });
        }
    };

    callFetch(
        signupApiPostSignupProcessingEndpoint,
        {
            method: 'get',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        },
        onSuccess,
        onFailure
    );
};

export const sanitizeString = (str) => {
    if (typeof str !== 'string') {
        // If not a string, convert it to string or handle accordingly
        str = String(str);
    }

    // Remove HTML tags
    str = str.replace(/<\/?[^>]+(>|$)/g, '');

    // Encode special characters
    const encodings = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;',
    };

    return str.replace(/[&<>"'`=\/]/g, (match) => encodings[match]);
};

export const NOGATSBYLINK_URL_PATTERNS = ['/blog', /\.pdf$/];

export const isGatsbyLinkable = (url) => {
    return NOGATSBYLINK_URL_PATTERNS.some((pattern) =>
        pattern instanceof RegExp ? pattern.test(url) : url.startsWith(pattern)
    );
};

export const parseRichText = memoizeFn(
    (richTextStr, returnMode = 'bool') => {
        if (!richTextStr?.raw) {
            return null;
        }

        try {
            const obj = JSON.parse(richTextStr.raw);
            const valueStr = obj?.content
                ?.reduce((acc, next) => {
                    if (next?.content) {
                        const extractedData = next?.content?.reduce((a, n) => {
                            if (
                                n?.value ||
                                n?.nodeType === 'entry-hyperlink' ||
                                n?.nodeType === 'hyperlink' ||
                                n?.nodeType === 'embedded-entry-inline'
                            ) {
                                a = a.concat(
                                    n?.value ||
                                        'entry-hyperlink' ||
                                        'hyperlink' ||
                                        'embedded-entry-inline'
                                );
                            }
                            return a;
                        }, '');

                        if (extractedData) {
                            acc = acc.concat(extractedData);
                        }
                    }

                    /* ensure that complete embedded entry blocks are counted */
                    if (next?.nodeType === 'embedded-entry-block') {
                        acc = acc.concat('embedded-entry-block');
                    }

                    return acc;
                }, '')
                ?.trim();

            if (returnMode === 'string') {
                return valueStr;
            }

            return Boolean(valueStr);
        } catch (err) {
            if (returnMode === 'string') {
                return '';
            }

            return false;
        }
    },
    { comparators }
);

export const isDev = () => {
    // create .env.development file in root and DEV=TRUE
    // otherwise this always returns true
    return process.env.DEV === 'TRUE';
};

export const getDefaultLocation = (pathname) => {
    const pagePrefix = pathname
        ? pathname.slice(1, pathname.length).split('/')[0]
        : '';

    const localeMapping = {
        au: 'au',
        ca: 'ca',
        uk: 'uk',
        nz: 'nz',
    };

    if (pagePrefix && localeMapping[pagePrefix]) {
        return localeMapping[pagePrefix];
    }

    return '';
};

export const getCountryCodeAndCurrency = (defaultLocation) => {
    if (defaultLocation && countryCodesToCurrencies[defaultLocation]) {
        return countryCodesToCurrencies[defaultLocation];
    }

    return countryCodesToCurrencies.us;
};

export const getPathnameRedirect = (currencySelected, pathname) => {
    try {
        const redirectMap = {
            'AUD (A$)': 'au',
            'CAD (CA$)': 'ca',
            'GBP (£)': 'uk',
            'NZD (NZ$)': 'nz',
        };

        const pathParts = pathname?.slice(1, pathname.length)?.split('/');
        const activePathFolder = pathParts[0];

        const homepagePaths = ['/', '/ca', '/au', '/uk', 'nz'];

        if (homepagePaths?.includes(pathname)) {
            if (redirectMap[currencySelected]) {
                return `/${redirectMap[currencySelected]}`;
            }

            return '/';
        }

        let newUrl = pathname;
        if (
            Object.keys(redirectMap).includes(currencySelected) &&
            activePathFolder !== redirectMap[pathname]
        ) {
            if (Object.values(redirectMap).includes(activePathFolder)) {
                newUrl = `/${pathParts
                    .reduce((acc, next, idx) => {
                        if (
                            idx === 0 &&
                            next === activePathFolder &&
                            next !== redirectMap[currencySelected]
                        ) {
                            acc = acc.concat(redirectMap[currencySelected]);
                        } else {
                            acc = acc.concat(next);
                        }
                        return acc;
                    }, [])
                    .join('/')}`;
            } else {
                newUrl = `/${[redirectMap[currencySelected], ...pathParts].join(
                    '/'
                )}`;
            }
        } else if (!Object.keys(redirectMap).includes(currencySelected)) {
            if (Object.values(redirectMap).includes(activePathFolder)) {
                newUrl = `/${pathParts
                    .reduce((acc, next, idx) => {
                        if (idx > 0) {
                            acc = acc.concat(next);
                        }
                        return acc;
                    }, [])
                    .join('/')}`;
            }
        }

        const duplicatedPages = [
            'features',
            'pricing',
            'why-us',
            'trial/signup',
            'buynow/signup',
            'buynow/plans',
            'signup',
        ];

        const validPathnames = [
            '/',
            ...Object.values(redirectMap),
            ...duplicatedPages.reduce(
                (acc, next) => (acc = acc.concat(`/${next}`)),
                []
            ),
            ...Object.values(redirectMap).reduce((acc, next) => {
                acc = acc.concat(
                    duplicatedPages.reduce((a, n) => {
                        a = a.concat(`/${next}/${n}`);
                        return a;
                    }, [])
                );
                return acc;
            }, []),
        ];

        return validPathnames?.includes(newUrl)
            ? newUrl
            : `/${newUrl?.slice(1, newUrl.length)?.split('/')[0]}`;
    } catch (error) {
        return pathname;
    }
};

export const setStorageItem = (key, value) => {
    try {
        if (typeof window !== 'undefined') {
            sessionStorage.setItem(key, value);
        }
    } catch (error) {
        if (typeof window !== 'undefined') {
            console.warn(error);
        }
    }
};

export const getStorageItem = (key, isJSON = false) => {
    if (typeof window !== 'undefined') {
        try {
            if (isJSON) {
                return JSON.parse(sessionStorage.getItem(key));
            }

            return sessionStorage.getItem(key);
        } catch (error) {
            console.warn(error);
            if (isJSON) {
                return {};
            }

            return '';
        }
    }

    if (isJSON) {
        return {};
    }

    return '';
};

export const buildRelativeLink = (url) => {
    try {
        if (typeof window !== 'undefined') {
            const { protocol, host } = window.location;

            return `${protocol}//${host}${url}`;
        }

        return null;
    } catch (error) {
        return null;
    }
};

export const isNoFollowLink = (url) => {
    if (!url) {
        return null;
    }

    try {
        if (typeof window !== 'undefined') {
            const { pathname: pagePathname } = window.location;
            const fullyQualifiedUrl =
                url && url.startsWith('/') ? buildRelativeLink(url) : url;

            if (!fullyQualifiedUrl || !isValidHttpUrl(fullyQualifiedUrl)) {
                return null;
            }

            const {
                host,
                pathname,
                hash = null,
                search,
            } = new URL(fullyQualifiedUrl);

            const isConstantContactUrl = host?.match(
                /l1\.constantcontact\.com|s1\.constantcontact\.com|www\.constantcontact\.com/
            );

            if (isConstantContactUrl && (pathname === pagePathname || hash)) {
                return 'nofollow';
            }

            return null;
        }

        return null;
    } catch (error) {
        if (typeof window !== 'undefined' && window?.console) {
            console.warn(error);
        }
        return null;
    }
};

export const trimString = (str, len = 64) =>
    str && typeof str === 'string' ? str.substring(0, len) : str;

export const findFirstHeadingInRawJson = (jsonString) => {
    try {
        const data = (jsonString && JSON.parse(jsonString)) || {};
        if (data?.content?.length) {
            for (let i = 0; i < data.content.length; i++) {
                const node = data.content[i];
                if (node.nodeType && /^heading-[1-6]$/.test(node.nodeType)) {
                    const headingType = node.nodeType;
                    const headingContent = node.content
                        .map((contentNode) => contentNode.value)
                        .join('');
                    return {
                        headingType,
                        content: headingContent,
                        node,
                    };
                }
            }
        }

        return null;
    } catch (error) {
        console.error('Invalid JSON:', error);
        return null;
    }
};

export const throttleWindowResize = (callback, delay) => {
    let resizeTimer;
    return () => {
        if (!resizeTimer) {
            resizeTimer = setTimeout(() => {
                resizeTimer = null;
                callback();
            }, delay);
        }
    };
};

export function getVisibleHeight(el) {
    if (!el) {
        return 0;
    }

    const zeroIfNegative = (v) => (v < 0 ? 0 : v);
    const docElement = el.ownerDocument.documentElement;
    const viewportHeight = docElement.clientHeight;
    const rect = el.getBoundingClientRect();
    const vStart = zeroIfNegative(-rect.top);
    const vEnd = rect.height - zeroIfNegative(rect.bottom - viewportHeight);

    return vEnd - vStart || 0;
}

export const extractWistiaLinks = (items) => {
    try {
        const links = [];

        /* second match condition should fix video preview */
        const rawMatches = JSON.stringify(items).match(
            /ContentfulVideo|"_type":"video"/gi
        );

        if (rawMatches?.length) {
            return rawMatches;
        }

        const findVideos = (arrayOrObject) => {
            // some items are arrays so we need to loop through those
            if (Array.isArray(arrayOrObject)) {
                for (const item of arrayOrObject) {
                    if (findVideos(item)) return true;
                }
                // other items are just reference fields where a single video could be uploaded
            } else if (arrayOrObject && typeof arrayOrObject === 'object') {
                const item = arrayOrObject;

                // Check if this object is a "ContentfulVideo" with a wistiaShareLink
                if (
                    item.__typename === 'ContentfulVideo' &&
                    typeof item.wistiaShareLink === 'string' &&
                    item.wistiaShareLink.trim() !== ''
                ) {
                    links.push({
                        link: item.wistiaShareLink.split('/').pop(),
                        loop: item.loop,
                        id: item?.contentful_id || item?.id,
                        title: item?.title || null,
                        imageAlt: item?.imageAlt || null,
                    });
                    return true;
                }

                // recursively check nested properties
                if (item.components && findVideos(item.components)) return true;
                if (item.columns && findVideos(item.columns)) return true;
                if (item.imageOrVideo && findVideos(item.imageOrVideo))
                    return true;
            }

            return false;
        };

        findVideos(items);

        return links;
    } catch (error) {
        console.error('Error extracting Wistia links:', error);
        return null;
    }
};

export const getStringifiedWysiwygContent = (obj, fallback) => {
    try {
        return obj?.content && Array.isArray(obj?.content)
            ? obj.content.reduce((acc, next) => {
                  if (next?.value) {
                      acc = acc.concat(next.value);
                  }
                  return acc;
              }, '')
            : fallback;
    } catch (error) {
        return fallback;
    }
};

export const checkForComponentInstances = memoizeFn(
    (
        items,
        componentsRexeg = /ContentfulColumnWrapper|ContentfulTwoColumnBlock|"_type":"twoColumnBlock"|"_type":"columnWrapper"/gi
    ) => {
        try {
            const rawMatches = JSON.stringify(items).match(componentsRexeg);

            if (rawMatches?.length) {
                return true;
            }

            return false;
        } catch (err) {
            return false;
        }
    },
    { comparators }
);

export const urlUpdater = {
    cleanUrl: () => {
        if (typeof window === 'undefined') return;
        try {
            const url = new URL(window.location.href);
            const { searchParams } = url;
            let modified = false;

            // Retrieve existing stored values from sessionStorage if any
            const storedString = sessionStorage.getItem('formHelpers');
            const storedObj = storedString ? JSON.parse(storedString) : {};

            // Retrieve values before deleting them from the URL
            const firstName = searchParams.get('firstName');
            const lastName = searchParams.get('lastName');

            if (firstName) {
                storedObj.givenName = firstName;
                searchParams.delete('firstName');
                modified = true;
            }

            if (lastName) {
                storedObj.familyName = lastName;
                searchParams.delete('lastName');
                modified = true;
            }

            if (modified) {
                // Only update sessionStorage if we found PII.
                sessionStorage.setItem(
                    'formHelpers',
                    JSON.stringify(storedObj)
                );

                const newSearch = searchParams.toString();
                // URL cleaned and PII stored in sessionStorage
                history.replaceState(
                    null,
                    '',
                    url.pathname + (newSearch ? `?${newSearch}` : '')
                );
            }
        } catch (error) {
            if (rg4js) {
                rg4js('send', {
                    error,
                    tags: ['cleanUrl_error'],
                });
            }
        }
    },
};

export const storeCampaignParamsInLocalStorage = () => {
    try {
        /* this function should only run once to completion on the initial page visit */
        if (sessionStorage.getItem('utmCampaignStorageRoutineCompleted')) {
            return;
        }

        const { searchParams } = new URL(window.location.href);

        if (searchParams && searchParams?.size) {
            const utmCampaignParams = buildDcObject(searchParams);
            if (utmCampaignParams && Object.keys(utmCampaignParams).length) {
                localStorage.setItem(
                    'utmCampaignParams',
                    JSON.stringify(utmCampaignParams)
                );
            }
        }

        sessionStorage.setItem('utmCampaignStorageRoutineCompleted', true);
    } catch (error) {
        /* if something about this script triggers an error, make sure it doesn't run again */
        sessionStorage.setItem('utmCampaignStorageRoutineCompleted', true);
    }
};
