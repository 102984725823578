module.exports = {
    email: 'siteContactEmailLogin',
    givenName: 'siteContactFirstName',
    familyName: 'siteContactLastName',
    organization: 'siteName',
    tel: 'siteContactPhoneDisplay',
    country: 'siteContactCountryCode',
    siteContactCountryCode: 'siteContactCountryCode',
    url: 'url',
};
